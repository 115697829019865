import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Link, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Field, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import ScContainer from '../../../Components/Layout/ScContainer/ScContainer';
import { PageHeader } from '../../../Components/_base/BBQGuysComponents';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    MIN_PASSWORD_CHARS,
    UpdatePasswordSchema,
} from '../../../Schemas/Schemas';
import { empty } from '../../../functions';
import { EmailWithLabel } from '../../Account/PageEditMyAccount/Components/EmailWithLabel';
import { PasswordWithLabel } from '../../Account/PageEditMyAccount/Components/PasswordWithLabel';

interface UpdatePasswordValues {
    email: string;
    newPassword: string;
    newPasswordConfirm: string;
    token: string;
}

export const UpdatePasswordForm = () => {
    const {
        updatePassword,
        errorMessage,
        successMessage,
        setErrorMessage,
        setSuccessMessage,
        user,
    } = React.useContext(UserAuth);
    React.useEffect(() => {
        setSuccessMessage('');
        setErrorMessage('');
    }, []);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token') ?? '';

    if (empty(token)) {
        return (
            <ScContainer size="small" sx={{ mt: 4 }}>
                <Alert severity="success">
                    <AlertTitle>
                        A password reset link has been sent to your email
                    </AlertTitle>
                </Alert>
            </ScContainer>
        );
    }

    const formik = useFormik({
        initialValues: {
            email: user.email ?? '',
            newPassword: '',
            newPasswordConfirm: '',
            token: token,
        },
        validationSchema: UpdatePasswordSchema,
        onSubmit: (values: UpdatePasswordValues, actions: any) => {
            actions.setSubmitting(true);
            updatePassword(values.email, values.token, values.newPassword)
                .then(() => {
                    setSuccessMessage('Password updated.');
                })
                .catch(message => {
                    setErrorMessage(message);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });

            formik.resetForm();
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <PageHeader title="Update Password" />
                {errorMessage && errorMessage.length > 0 ? (
                    <Alert severity="error">{errorMessage}</Alert>
                ) : null}
                {successMessage && successMessage.length > 0 ? (
                    <>
                        <Alert severity="success">{successMessage}</Alert>
                        <Link
                            href="/login"
                            sx={{
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            Click here to sign in with your new password
                        </Link>
                    </>
                ) : (
                    <Stack>
                        <EmailWithLabel
                            id="email"
                            name="email"
                            formik={formik}
                        />
                        <PasswordWithLabel
                            label={
                                <>
                                    New Password{' '}
                                    <em style={{ marginLeft: '0.3125rem' }}>
                                        (minimum of {MIN_PASSWORD_CHARS}{' '}
                                        characters)
                                    </em>
                                </>
                            }
                            id="newPassword"
                            name="newPassword"
                            formik={formik}
                            create
                        />
                        <PasswordWithLabel
                            label={
                                <>
                                    Confirm New Password{' '}
                                    <em style={{ marginLeft: '0.3125rem' }}>
                                        (must match previous)
                                    </em>
                                </>
                            }
                            id="newPasswordConfirm"
                            name="newPasswordConfirm"
                            formik={formik}
                            create
                        />
                        <Field
                            id="token"
                            name="token"
                            type="hidden"
                            formik={formik}
                        />
                        <Box display="flex" pt={2}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={formik.isSubmitting}
                            >
                                Update Password
                            </LoadingButton>
                        </Box>
                    </Stack>
                )}
            </form>
        </FormikProvider>
    );
};
