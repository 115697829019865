import window from 'global/window';

export const getEnvironmentVariable = (name: string): string => {
    if (process.env['REACT_APP_' + name]) {
        return `${process.env['REACT_APP_' + name]}`;
    } else if (process.env[name]) {
        return `${process.env[name]}`;
    } else {
        throw Error(`Environment Variable '${name}' not found.`);
    }
};
export const getDomain = () => {
    return getEnvironmentVariable('DOMAIN') ?? '';
};
export const isDevelopment = () => {
    return getEnvironmentVariable('ENV') == 'development';
};
export const isTesting = () => {
    return (
        getEnvironmentVariable('ENV') == 'test' ||
        getEnvironmentVariable('ENV') == 'testing' ||
        getEnvironmentVariable('ENV') == 'staging'
    );
};
export const isProduction = () => {
    return (
        getEnvironmentVariable('ENV') === 'production' ||
        (!isDevelopment() && !isTesting())
    );
};
export const isAdobeLaunchDevelopment = () => {
    return getEnvironmentVariable('ADOBE_ENV') == 'development';
};
export const isAdobeLaunchTesting = () => {
    return getEnvironmentVariable('ADOBE_ENV') == 'staging';
};
export const isAdobeLaunchProduction = () => {
    return getEnvironmentVariable('ADOBE_ENV') === 'production';
};

export const isThirdPartyProduction = () => {
    return isAdobeLaunchProduction();
};

export const isHTTPS = () => {
    return typeof window !== 'undefined' &&
        typeof window.location !== 'undefined'
        ? window.location.protocol === 'https:'
        : true;
};
