import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
// import reportWebVitals from './reportWebVitals';
import document from 'global/document';
import { loadableReady } from '@loadable/component';
// import { isProduction } from './config';

try {
    globalThis.__DEV__ = globalThis.isDevelopment();
} catch (error) {
    // I don't think it's important enough to throw anything
    // We'll see it not working because we'll get apollo messages
}

// Suppressing react hydration errors
window.onerror = function (message) {
    if (message && message.includes('Minified React error')) {
        return true;
    }
    return false;
};

// const onRecoverableError = (error, errorInfo) => {
//     if (!isProduction()) {
//         console.error(error, errorInfo);
//     }
// }

loadableReady(() => {
    const container = document.getElementById('root');
    hydrateRoot(
        container,
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        // {onRecoverableError}
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// function sendToAnalytics({ id, name, value }) {
//     ga('send', 'event', {
//       eventCategory: 'Web Vitals',
//       eventAction: name,
//       eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//       eventLabel: id, // id unique to current page load
//       nonInteraction: true, // avoids affecting bounce rate
//     });
//   }

//   reportWebVitals(sendToAnalytics);
