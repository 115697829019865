import * as React from 'react';
import { ImageType, Item } from '../types';
import { client } from '../Graphql/ApolloClient';
import { QL_ITEM_IMAGES } from '../Graphql/queries';
import { openPhotoSwipe } from '../Components/ImageCarousel/ImageCarousel';

export const ImageCarouselContext = React.createContext({
    item: {} as Item,
    images: [] as ImageType[],
    loading: true,
    numberOfVisibleThumbnails: 6,
    showVideoAnd360Thumbnails: true,
    zoomEnabled: true,
    selectedImageIndex: 0,
    carouselItemsRef: React.createRef() as React.MutableRefObject<
        HTMLAnchorElement[] | null[]
    >,
    carouselSelectedImageRef: React.createRef() as React.MutableRefObject<
        HTMLDivElement[] | null[]
    >,
    selectedImage: null as ImageType | null,
    fetchImages: (): Promise<ImageType[]> => {
        return new Promise((resolve, reject) => {});
    },
    setSelectedImageIndex: (number: number) => {
        return;
    },
    setSelectedImage: (img: ImageType) => {
        return;
    },
    setLoading: (val: boolean) => {
        return;
    },
    openPhotoViewer: (
        imgIndex?: number,
        onChange?: (newIdx: number) => void,
    ) => {
        return;
    },
    handleSelectedImageChange: (newIdx: number) => {
        return;
    },
    handleRightClick: () => {
        return;
    },
    handleLeftClick: () => {
        return;
    },
});

export function ImageCarouselProvider({
    item,
    itemImages = [],
    numberOfVisibleThumbnails = 6,
    showVideoAnd360Thumbnails = true,
    zoomEnabled = true,
    children,
}: {
    item: Item;
    itemImages?: ImageType[];
    numberOfVisibleThumbnails?: number;
    showVideoAnd360Thumbnails?: boolean;
    zoomEnabled?: boolean;
    children: any;
}) {
    const defaultImage = {
        description: item.name,
        url: item.imageUrl,
    } as ImageType;
    const [images, setImages] = React.useState<ImageType[]>(itemImages);
    const carouselItemsRef = React.useRef<HTMLAnchorElement[]>([]);
    const carouselSelectedImageRef = React.useRef<HTMLDivElement[]>([]);

    const [selectedImageIndex, setSelectedImageIndexVal] =
        React.useState<number>(0);

    const [selectedImage, setSelectedImageVal] =
        React.useState<ImageType | null>(defaultImage);

    const [loading, setLoadingVal] = React.useState(itemImages.length === 0);
    React.useEffect(() => {
        if (itemImages.length === 0) {
            fetchImages().finally(() => {
                setLoadingVal(false);
            });
        }
    }, []);

    const setSelectedImageIndex = (number: number) => {
        setSelectedImageIndexVal(number);
    };
    const setSelectedImage = (img: ImageType) => {
        setSelectedImageVal(img);
    };
    const setLoading = (val: boolean) => {
        setLoadingVal(val);
    };

    const openPhotoViewer = (
        imgIndex?: number,
        onChange?: (newIdx: number) => void,
    ) => {
        if (imgIndex === undefined) {
            imgIndex = selectedImageIndex;
        }
        if (!zoomEnabled) {
            return;
        }
        openPhotoSwipe(imgIndex, images, index => {
            if (typeof onChange === 'function') {
                onChange(index);
            } else {
                handleSelectedImageChange(index);
            }
        });
    };

    const handleSelectedImageChange = (newIdx: number) => {
        if (images && images.length > 0) {
            setSelectedImage(images[newIdx]);
            setSelectedImageIndex(newIdx);
            if (carouselItemsRef?.current[newIdx]) {
                carouselItemsRef?.current[newIdx]?.scrollIntoView({
                    behavior: 'instant',
                    block: 'nearest',
                    inline: 'center',
                });
            }
        }
    };

    const handleRightClick = () => {
        if (images && images.length > 0) {
            let newIdx = selectedImageIndex + 1;
            if (newIdx >= images.length) {
                newIdx = 0;
            }
            handleSelectedImageChange(newIdx);
        }
    };

    const handleLeftClick = () => {
        if (images && images.length > 0) {
            let newIdx = selectedImageIndex - 1;
            if (newIdx < 0) {
                newIdx = images.length - 1;
            }
            handleSelectedImageChange(newIdx);
        }
    };

    const fetchImages = async (): Promise<ImageType[]> => {
        return new Promise((resolve, reject) => {
            (async () => {
                const { data } = await client.query({
                    query: QL_ITEM_IMAGES,
                    variables: { itemId: item.id },
                });

                const dataImages: ImageType[] = data?.itemImages || [];
                const itemImages: ImageType[] = [
                    {
                        id: '1',
                        url: item.imageUrl,
                        description: item.name,
                    } as ImageType,
                ].concat(
                    dataImages.filter(
                        (img: ImageType) => img.url !== item.imageUrl,
                    ),
                );

                setImages(itemImages);

                if (itemImages[0]) {
                    carouselItemsRef.current = carouselItemsRef.current.slice(
                        0,
                        itemImages.length,
                    );
                    carouselSelectedImageRef.current =
                        carouselSelectedImageRef.current.slice(0, 0);
                    setSelectedImageIndex(0);
                    setSelectedImage(itemImages[0]);
                }
                resolve(itemImages);
            })();
        });
    };

    return (
        <ImageCarouselContext.Provider
            value={{
                item,
                images,
                loading,
                numberOfVisibleThumbnails,
                showVideoAnd360Thumbnails,
                zoomEnabled,
                fetchImages,
                setLoading,
                selectedImageIndex,
                setSelectedImageIndex,
                selectedImage,
                setSelectedImage,
                carouselItemsRef,
                carouselSelectedImageRef,
                openPhotoViewer,
                handleSelectedImageChange,
                handleRightClick,
                handleLeftClick,
            }}
        >
            {children}
        </ImageCarouselContext.Provider>
    );
}
