import Slider from 'react-slick';
import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { getEnvironmentVariable } from '../../config';
import { Image } from '../Image';
import './../../Components/Banners/Components/Banners.scss';
import './ImageCarousel.scss';
import { ImageCarouselContext } from '../../Contexts/ImageCarouselContext';
import document from 'global/document';

const ImageCarouselMobileSlickSlider = () => {
    const {
        images,
        openPhotoViewer,
        setSelectedImageIndex,
        carouselItemsRef,
        loading,
    } = React.useContext(ImageCarouselContext);

    const indexSlideCount = React.useRef<HTMLSpanElement>(null);
    const [containerWidth, setContainerWidth] = React.useState(
        document.documentElement.clientWidth ?? '100%',
    );
    const sliderRef = React.useRef<Slider>(null);

    const resizeEvent = () => {
        if (document) {
            setContainerWidth(document.documentElement.clientWidth);
        }
    };
    React.useEffect(() => {
        window.addEventListener('resize', resizeEvent);
        return () => {
            window.removeEventListener('resize', resizeEvent);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        autoplay: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (currentSlide: number) => {
            if (indexSlideCount.current) {
                const i = (currentSlide ? currentSlide : 0) + 1;
                indexSlideCount.current.innerText = `${i}`;
                setSelectedImageIndex(i - 1);
            }
        },
    };
    return (
        <Box
            sx={{
                whiteSpace: 'nowrap',
                overflowX: 'scroll',
                marginLeft: '-5.5%',
                marginRight: '-5%',
                scrollBehavior: 'smooth',
                minHeight: '50vw',
                '&::-webkit-scrollbar': {
                    height: '1px',
                    overflow: 'visible',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    borderRadius: 10,
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#333',
                    height: '4px',
                    overflow: 'visible',
                    borderRadius: 10,
                },
            }}
            style={{ width: containerWidth }}
        >
            {loading ? (
                <Box textAlign="right">
                    <Skeleton
                        variant="text"
                        width="4rem"
                        height="1.5rem"
                        sx={{ display: 'inline-block' }}
                    />
                </Box>
            ) : (
                <>
                    {images && (
                        <Slider {...settings} ref={sliderRef}>
                            {images.map((image, idx) => (
                                <a
                                    href={image.url}
                                    key={'img-slick-' + idx}
                                    className={`carousel__image`}
                                    aria-hidden="true"
                                    target="_blank"
                                    rel="noreferrer"
                                    ref={el =>
                                        (carouselItemsRef.current[idx] = el)
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        openPhotoViewer(idx, newIdx => {
                                            if (sliderRef.current) {
                                                sliderRef.current.slickGoTo(
                                                    newIdx,
                                                );
                                            }
                                        });
                                    }}
                                >
                                    <Image
                                        src={image.url}
                                        alt={image.description ?? ''}
                                        width={800}
                                        height={800}
                                        lazyLoadImage={idx === 0 ? true : false}
                                        fullWidth
                                        onError={({
                                            currentTarget,
                                        }: {
                                            currentTarget: any;
                                        }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                                getEnvironmentVariable(
                                                    'NO_IMAGE_URL',
                                                );
                                        }}
                                    />
                                </a>
                            ))}
                        </Slider>
                    )}
                    {images !== undefined && images.length > 1 ? (
                        <Box textAlign="right" marginX={'2%'}>
                            <Typography variant="bold">
                                <span id="img-current" ref={indexSlideCount}>
                                    1
                                </span>{' '}
                                of <span id="img-total">{images.length}</span>
                            </Typography>
                        </Box>
                    ) : null}
                </>
            )}
        </Box>
    );
};

export default ImageCarouselMobileSlickSlider;
