import { useQuery } from '@apollo/client';
import { Add, Remove } from '@mui/icons-material';
import {
    Avatar,
    Button,
    Chip,
    FormControlLabel,
    FormGroup,
    Skeleton,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    GridPageTypeValues,
    ProductGridRefinement,
} from '../../Contexts/ProductGridRefinementProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { QL_FACETS_DEALS, QL_FACETS_SEARCH } from '../../Graphql/queries';
import { slugify } from '../../functions';
import { Attribute, Facet } from '../../types';
import {
    AskAnExpert,
    CheckboxWithLabel,
    HelpTipIcon,
} from '../_base/BBQGuysComponents';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import './ProductFacets.scss';
import Modal from '../_base/BBQGuysModal';
import CloseIcon from '@mui/icons-material/Close';

type ProductFacetTypes = 'department' | 'search' | 'deal';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

interface AccordionSummaryPropsWithHelptip extends AccordionSummaryProps {
    helpContentId?: string;
    expanded?: boolean;
    loading?: boolean;
}

const AccordionSummary = styled((props: AccordionSummaryPropsWithHelptip) => (
    <Box
        display="grid"
        gridTemplateColumns={'1fr 40px'}
        gap={1}
        sx={{
            alignItems: 'center',
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
    >
        <MuiAccordionSummary
            expandIcon={
                props.loading ? (
                    <Remove
                        sx={{
                            fontSize: '1.25rem',
                            color: theme => theme.palette.divider,
                        }}
                    />
                ) : props.expanded ? (
                    <Remove
                        sx={{ fontSize: '1.25rem' }}
                        color="primary"
                        shapeRendering="crispEdges"
                    />
                ) : (
                    <Add
                        sx={{ fontSize: '1.25rem' }}
                        color="primary"
                        shapeRendering="crispEdges"
                    />
                )
            }
            sx={{ px: 0 }}
            {...[props].map(
                ({ helpContentId, expanded, loading, ...rest }) => rest,
            )[0]}
        />
        {props.helpContentId !== undefined ? (
            <HelpTipIcon
                contentId={props.helpContentId as string}
                title={props.title}
            />
        ) : null}
    </Box>
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#fff',
    border: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0, 0, 2, 0),
}));

const AttributeRowLoading = (props: { width?: number }) => {
    const width = props.width ? props.width : 75;
    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox disabled />}
                label={<Skeleton variant="rectangular" width={width} />}
            />
        </FormGroup>
    );
};

const AttributeRow = (props: { attribute: Attribute }) => {
    const { attribute } = props;
    const [checked, setChecked] = React.useState(
        attribute.checked ? true : false,
    );
    const { addAttribute, removeAttribute } = React.useContext(
        ProductGridRefinement,
    );

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        attribute: Attribute,
    ) => {
        setChecked(event.target.checked);
        if (!event.target.checked) {
            removeAttribute(attribute);
        } else {
            addAttribute(attribute);
        }
    };

    useEffect(() => {
        setChecked(attribute.checked);
    }, [attribute.checked]);

    return (
        <CheckboxWithLabel
            selectable={attribute.selectable}
            checked={checked}
            label={
                attribute.count ? (
                    <>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: attribute.label,
                            }}
                        />{' '}
                        <Typography variant="muted" component="span">
                            ({attribute.count ?? 0})
                        </Typography>
                    </>
                ) : (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: attribute.label,
                        }}
                    />
                )
            }
            labelVariant="link"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, attribute)
            }
            tabIndex={-1}
        />
    );
};

function facetRowRenderer(
    attr: Attribute,
    index: number, // Unique key within array of rows
    style?: any,
) {
    return (
        <div
            className="facet-attr-row"
            key={'facet-' + attr.labelId}
            style={style}
            role="row"
            tabIndex={0}
        >
            <AttributeRow attribute={attr} />
        </div>
    );
}

//const FacetAttributesVirtualList = (props: {
//    expanded: boolean;
//    attributes: Attribute[];
//}) => {
//    const ROW_HEIGHT = 30;
//    const HEIGHT =
//        props.attributes.length > 7
//            ? ROW_HEIGHT * 7
//            : ROW_HEIGHT * props.attributes.length;
//    if (!props.expanded) {
//        return <></>;
//    }
//    return (
//        <List
//            role="grid"
//            autoWidth
//            width={300}
//            height={HEIGHT}
//            rowCount={props.attributes.length}
//            rowHeight={ROW_HEIGHT}
//            style={{ paddingBottom: theme.spacing(1), overflowY: 'scroll' }}
//            rowRenderer={({ index, style }) =>
//                facetRowRenderer(props.attributes[index] as Attribute, index, {
//                    ...style,
//                    height: 'auto',
//                })
//            }
//        />
//    );
//};

const FacetAttributes = (props: {
    expanded: boolean;
    attributes: Attribute[];
}) => {
    return (
        <>
            {props.attributes.map((bute: Attribute, i: number) => {
                return facetRowRenderer(bute as Attribute, i, {
                    height: 'auto',
                });
            })}
        </>
    );
};

const ProductFacet = (props: { facet: Facet; expandedByDefault: boolean }) => {
    const { facet, expandedByDefault } = props;
    const [expanded, setExpanded] = React.useState<boolean>(
        expandedByDefault ||
            (
                facet.attributes?.filter((attr, i) => attr?.checked === true) ||
                []
            ).length > 0,
    );
    const [moreShown, setMoreShown] = React.useState(false);
    const displayShowMoreBtn = facet.attributes && facet.attributes.length > 10;
    const ROW_HEIGHT = 36;
    const maxHeight = moreShown
        ? Math.floor(ROW_HEIGHT * 16.6)
        : ROW_HEIGHT * 10;

    const handleChange = (panel: boolean) => () => {
        setExpanded(panel);
    };
    const toggleShowMore = () => {
        setMoreShown(!moreShown);
    };
    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={handleChange(!expanded)}
                sx={{
                    borderTop: 0,
                    borderLeft: 0,
                    borderBottomWidth: expanded ? 1 : 0,
                }}
            >
                <AccordionSummary
                    title={facet.name || undefined}
                    aria-controls={slugify(facet.name) + '-content'}
                    id={slugify(facet.name) + '-header'}
                    expanded={expanded}
                    helpContentId={facet.helpContentId || undefined}
                >
                    <Typography variant="bold" lineHeight={1}>
                        {facet.name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        overflowY: moreShown ? 'auto' : 'hidden',
                        maxHeight: displayShowMoreBtn ? maxHeight : 'unset',
                    }}
                >
                    <FacetAttributes
                        expanded={expanded}
                        attributes={facet.attributes as Attribute[]}
                    />
                </AccordionDetails>
                {displayShowMoreBtn ? (
                    <Button
                        variant="link"
                        className="facet-show-more"
                        fullWidth
                        onClick={() => toggleShowMore()}
                    >
                        Show {moreShown ? 'Less' : 'More'}
                    </Button>
                ) : null}
            </Accordion>
        </>
    );
};

export const ProductFacetsLoading = () => {
    const rand = 7;
    const widths = [125, 95, 102, 105, 140];
    return (
        <>
            {[...Array(rand)].map((n: unknown, i: number) => {
                const randAttributesCnt = 5;
                return (
                    <Accordion
                        expanded={true}
                        key={i}
                        sx={{
                            borderTop: 0,
                            borderLeft: 0,
                            borderBottomWidth: 1,
                        }}
                    >
                        <AccordionSummary id={n + '-header'} loading>
                            <Typography>
                                <Skeleton variant="text" width={160} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {[...Array(randAttributesCnt)].map(
                                (n2: unknown, i2: number) => {
                                    return (
                                        <AttributeRowLoading
                                            key={i2}
                                            width={widths[i2]}
                                        />
                                    );
                                },
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};

const FacetsLayoutDesktop = (props: { children: any }) => {
    return props.children;
};

const FacetsLayoutMobile = (props: { children: any }) => {
    const { children } = props;
    const { showFacets, setShowFacets, totalItemsCount, selectedAttributes } =
        React.useContext(ProductGridRefinement);

    const totalProductsStr =
        totalItemsCount.toString() +
        ' product' +
        (totalItemsCount == 1 ? '' : 's') +
        ' showing.';

    let applyText =
        'View ' +
        totalItemsCount +
        ' Result' +
        (totalItemsCount == 1 ? '' : 's');

    if (selectedAttributes.length == 0) {
        applyText = 'View All Results';
    }

    return (
        <Modal
            title={'Filter Products'}
            fullScreen
            open={showFacets}
            handleClose={() => setShowFacets(false)}
            expandFullscreen={true}
        >
            <Box
                sx={{
                    p: 2,
                }}
            >
                <Typography
                    variant="bold"
                    lineHeight={1}
                    sx={{
                        color: '#e86412',
                        fontSize: '1.5rem',
                    }}
                >
                    {totalProductsStr}
                </Typography>
            </Box>
            <Box marginBottom={'180px'}>{children}</Box>
            <Box
                sx={{
                    position: 'fixed',
                    width: '100%',
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: 'rgba(255, 255, 255, .1)',
                        boxShadow: 'inset 0 0 0 1px #fff',
                        backdropFilter: 'blur(0.125em)',
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setShowFacets(false)}
                        size="large"
                    >
                        {applyText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export interface SelectedAttribute {
    facetLabel: string;
    attributeLabel: string;
    attribute: Attribute;
}

export const SelectedFacets = (props: {
    selectedAttributes: SelectedAttribute[];
}) => {
    const { removeAttribute, removeAllAttributes } = React.useContext(
        ProductGridRefinement,
    );

    if (props.selectedAttributes.length <= 0) {
        return <></>;
    }

    const cancelAvatarStyle = {
        fill: 'transparent !important',
        color: 'transparent !important',
        backgroundColor: 'transparent !important',
        margin: 0,
        marginLeft: '0 !important',
        marginTop: '0 !important',
        marginBottom: '0 !important',
        padding: 0,
        height: '1rem',
    };

    const cancelStyle = {
        fill: 'rgba(40, 43, 45, 0.26)',
        color: 'rgba(40, 43, 45, .26)',
    };

    const cancelAllStyle = {
        fill: '#9d3337',
    };

    return (
        <Box
            sx={{
                borderTop: 'thin solid #d7dbdd',
                width: '100%',
            }}
        >
            {props.selectedAttributes.map((f: SelectedAttribute, i: number) => {
                const clickFunction = function () {
                    removeAttribute(f.attribute);
                };
                return (
                    <Chip
                        label={f.facetLabel + ': ' + f.attributeLabel}
                        key={i}
                        onClick={clickFunction}
                        avatar={
                            <Avatar sx={cancelAvatarStyle}>
                                <CancelRoundedIcon sx={cancelStyle} />
                            </Avatar>
                        }
                        sx={{
                            m: 0.5,
                            p: 0.5,
                        }}
                    />
                );
            })}
            {props.selectedAttributes.length > 1 ? (
                <Chip
                    label="Clear All"
                    onClick={removeAllAttributes}
                    avatar={
                        <Avatar sx={cancelAvatarStyle}>
                            <CloseIcon sx={cancelAllStyle} />
                        </Avatar>
                    }
                    variant="outlined"
                    sx={{
                        m: 0.5,
                        p: 0.5,
                        borderColor: 'transparent',
                    }}
                />
            ) : (
                <></>
            )}
        </Box>
    );
};

export const FacetsDisplay = (props: {
    facets: Facet[];
    loading?: boolean;
}) => {
    if (props.loading) {
        return <ProductFacetsLoading />;
    }

    const expandedByDefaultNames: string[] = ['Price'];
    const expandedFacets = props.facets.filter((f: any) =>
        expandedByDefaultNames.includes(f.name),
    );
    const otherFacets = props.facets.filter(
        (f: any) => !expandedByDefaultNames.includes(f.name),
    );

    const expandedByDefaultCount = 7;

    // Append facets with names in expandedByDefaultNames
    const insertionIndex = Math.min(
        expandedByDefaultCount -
            Math.min(expandedByDefaultCount, expandedFacets.length),
        otherFacets.length,
    );

    const combinedFacets = [...otherFacets];

    combinedFacets.splice(insertionIndex, 0, ...expandedFacets);

    if (combinedFacets && combinedFacets.length > 0) {
        return (
            <Box mb={4}>
                <MobileDevice>
                    <SelectedFacets
                        selectedAttributes={GetSelectedAttributes({
                            facets: combinedFacets,
                        })}
                    />
                </MobileDevice>
                {combinedFacets.map((f: Facet, i: number) => {
                    return (
                        <ProductFacet
                            key={i}
                            facet={f}
                            expandedByDefault={i < expandedByDefaultCount}
                        />
                    );
                })}
            </Box>
        );
    }
    return <></>;
};

const GetSelectedAttributes = (props: { facets: Facet[] }) => {
    const selectedAttributes = [] as SelectedAttribute[];
    const facets = props.facets;
    for (let i = 0; i < facets.length; ++i) {
        const facet = facets[i] as Facet;
        const facetAttributes = (facet?.attributes ?? []) as Attribute[];
        for (let j = 0; j < facetAttributes.length; ++j) {
            const attribute = facetAttributes[j] as Attribute;
            if (attribute && attribute.checked) {
                selectedAttributes.push({
                    facetLabel: facet?.name ?? '',
                    attributeLabel: attribute?.label ?? '',
                    attribute: attribute,
                });
            }
        }
    }
    return selectedAttributes;
};

let PREV_FACETS = [] as Facet[];

const SearchProductFacets = (props: {
    facets?: Facet[];
    typeId?: string | null;
    searchTerm?: string | null;
    selectedAttributes?: SelectedAttribute[];
}) => {
    if (props.facets !== undefined) {
        PREV_FACETS = props.facets;
    }
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const term = (query.get('sstring') as string) ?? props.searchTerm;
    const page = (query.get('page') as string) || '1';
    const sortValue = (query.get('sort') as string) || 'Relevance';
    query.delete('sstring');
    query.delete('page');
    query.delete('sort');
    const refinements = query.toString();
    const [facets, setFacets] = React.useState<Facet[]>(PREV_FACETS);
    const { data, loading } = useQuery(QL_FACETS_SEARCH, {
        variables: {
            term: term,
            page: parseInt(page),
            sort: sortValue,
            refinements: refinements,
            deptId: props.typeId,
        },
    });
    if (!loading && data?.searchFacets && data.searchFacets !== facets) {
        PREV_FACETS = data.searchFacets;
        setFacets(PREV_FACETS);
    }

    if (loading && facets.length === 0) {
        return <ProductFacetsLoading />;
    }
    return (
        <>
            <FacetsDisplay facets={facets} loading={false} />
            <DesktopAndTabletDevice>
                {facets.length !== 0 && <AskAnExpert />}
            </DesktopAndTabletDevice>
        </>
    );
};

const DepartmentProductFacets = (props: {
    deptId: string;
    facets?: Facet[];
}) => {
    if (props.facets !== undefined) {
        PREV_FACETS = props.facets;
    }

    // State to manage current facets
    const [facets, setFacets] = React.useState<Facet[]>(PREV_FACETS);
    const { getDeptFacets } = React.useContext(ProductGridRefinement);

    // Fetching department facets
    const { data, loading } = getDeptFacets(props.deptId);

    if (!loading && data.departmentFacets !== facets) {
        PREV_FACETS = data.departmentFacets;
        setFacets(PREV_FACETS);
    }

    // Render loading indicator if loading and no facets are available
    if (loading && facets.length === 0) {
        return <ProductFacetsLoading />;
    }

    // Render FacetsDisplay component with facets
    return <FacetsDisplay facets={facets} loading={false} />;
};

const DealsProductFacets = (props: { facets?: Facet[] }) => {
    if (props.facets !== undefined) {
        PREV_FACETS = props.facets;
    }
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const refinements = query.toString();
    const [facets, setFacets] = React.useState(PREV_FACETS as Facet[]);

    const { data, loading } = useQuery(QL_FACETS_DEALS, {
        variables: {
            refinements: refinements,
        },
    });

    if (!loading && data.warehouseDealFacets !== facets) {
        PREV_FACETS = data.warehouseDealFacets;
        setFacets(PREV_FACETS);
    }

    if (loading && facets.length === 0) {
        return <ProductFacetsLoading />;
    }

    return <FacetsDisplay facets={facets} loading={false} />;
};

const ProductFacetsByType = (props: {
    type: ProductFacetTypes;
    typeId?: string;
    facets?: Facet[];
    searchTerm?: string | null;
}) => {
    const { type, typeId = '', facets = [], searchTerm } = props;
    switch (type) {
        case 'deal':
            return <DealsProductFacets facets={facets} />;
        case 'search':
            return (
                <SearchProductFacets
                    facets={facets}
                    searchTerm={searchTerm}
                    typeId={typeId}
                />
            );
        default:
            return <DepartmentProductFacets deptId={typeId} facets={facets} />;
    }
};

const FacetLayouts = (props: {
    type: ProductFacetTypes;
    typeId?: string;
    facets?: Facet[];
    searchTerm?: string | null;
}) => {
    const { type, typeId = '', facets = [], searchTerm } = props;

    return (
        <>
            <DesktopAndTabletDevice>
                <FacetsLayoutDesktop>
                    <ProductFacetsByType
                        type={type}
                        typeId={typeId}
                        facets={facets}
                        searchTerm={searchTerm}
                    />
                </FacetsLayoutDesktop>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <FacetsLayoutMobile>
                    <ProductFacetsByType
                        type={type}
                        typeId={typeId}
                        facets={facets}
                        searchTerm={searchTerm}
                    />
                </FacetsLayoutMobile>
            </MobileDevice>
        </>
    );
};

export default function ProductFacets(props: {
    type: ProductFacetTypes;
    typeId?: string;
    facets?: Facet[];
    searchTerm?: string | null;
}) {
    const { setPageType } = React.useContext(ProductGridRefinement);
    const { type, typeId = '', facets = [], searchTerm } = props;

    useEffect(() => {
        if (['department', 'search'].indexOf(type) > -1) {
            setPageType(type as GridPageTypeValues);
        }
    }, []);

    return (
        <>
            <FacetLayouts
                type={type}
                typeId={typeId}
                facets={facets}
                searchTerm={searchTerm}
            />
        </>
    );
}
