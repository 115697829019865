import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    PlayArrow,
} from '@mui/icons-material';
import { Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { ImageType, Item, Maybe } from '../../types';
import { Image } from '../Image';
import Image360 from '../Image360/Image360';
import { ImageGalleryButton } from '../_base/BBQGuysStyledComponents';
import './ImageCarousel.scss';
import {
    ImageCarouselContext,
    ImageCarouselProvider,
} from '../../Contexts/ImageCarouselContext';
import { BBQGuysGrid } from '../_base/BBQGuysGrid';
import ImageCarouselMobileSlickSlider from './ImageCarouselMobileSlickSlider';

export type PhotoSwipeImageData = {
    src: string;
    width: number;
    height: number;
    alt: string;
};
export const IMAGE_CAROUSEL_THUMBNAIL_SIZE = 50;

export const ImageCarouselLoading = () => {
    const { numberOfVisibleThumbnails } =
        React.useContext(ImageCarouselContext);
    return (
        <>
            <DesktopAndTabletDevice>
                <Stack spacing={2}>
                    {Array(numberOfVisibleThumbnails)
                        .fill(0)
                        .map((v, i) => {
                            return (
                                <Skeleton
                                    key={'img-thumb-skeleton-' + i}
                                    variant="rectangular"
                                    height={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                    width={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                />
                            );
                        })}
                </Stack>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Skeleton variant="rectangular" height={400} width={'100%'} />
            </MobileDevice>
        </>
    );
};

const ImageCarousel: React.FC<{
    item: Item;
    itemImages?: ImageType[];
    numberOfVisibleThumbnails?: number;
    showVideoAnd360Thumbnails?: boolean;
    zoomEnabled?: boolean;
}> = ({
    item,
    itemImages = [],
    numberOfVisibleThumbnails = 6,
    showVideoAnd360Thumbnails = true,
    zoomEnabled = true,
}) => {
    return (
        <ImageCarouselProvider
            item={item}
            itemImages={itemImages}
            numberOfVisibleThumbnails={numberOfVisibleThumbnails}
            showVideoAnd360Thumbnails={showVideoAnd360Thumbnails}
            zoomEnabled={zoomEnabled}
        >
            <MobileDevice>
                <ImageCarouselMobileSlickSlider />
            </MobileDevice>
            <DesktopAndTabletDevice>
                <ImageCarouselDesktop />
            </DesktopAndTabletDevice>
        </ImageCarouselProvider>
    );
};

export const openPhotoSwipe = (
    idx: number,
    images: Maybe<ImageType>[],
    callback?: (index: number) => void,
    endOfImagesCallback?: (index: number, ps: any) => void,
) => {
    if (images === undefined || images.length < 1) {
        return;
    }
    const psImages = getPhotoSwapImageData(images);
    import('photoswipe/lightbox').then(({ default: PhotoSwipeLightbox }) => {
        const ps = new PhotoSwipeLightbox({
            bgOpacity: 1,
            gallery: '#selected-image',
            children: '.pswp-gallery__item',
            showHideAnimationType: 'none',
            dataSource: psImages,
            index: idx,
            secondaryZoomLevel: 2,
            maxZoomLevel: 3,
            pswpModule: () => import('photoswipe'),
        });
        ps.on('uiRegister', function () {
            ps.pswp.ui.registerElement({
                name: 'custom-caption',
                order: 9,
                isButton: false,
                appendTo: 'root',
                html: '',
            });
        });

        ps.on('change', () => {
            if (ps.pswp.currSlide.container) {
                const currSlideElement = ps.pswp.currSlide.container.parentNode;
                let captionHTML = '';
                const captionContainer = document.querySelector(
                    '.pswp__custom-caption',
                );
                if (ps.pswp.currSlide.content.element) {
                    captionHTML =
                        ps.pswp.currSlide.content.element.getAttribute('alt') ??
                        '';
                }
                let captionElement = currSlideElement.querySelector('caption');
                if (captionElement !== null) {
                    captionElement.innerHTML = '';
                }
                if (captionHTML) {
                    captionElement = document.createElement('caption');
                    captionElement.innerHTML = captionHTML || '';
                    if (captionContainer) {
                        captionContainer.replaceChildren(captionElement);
                    }
                }
                if (captionElement === null) {
                    if (captionContainer) {
                        captionContainer.innerHTML = '';
                    }
                }
            }
        });
        ps.on('close', () => {
            if (ps.pswp?.currIndex !== idx && typeof callback === 'function') {
                callback(ps.pswp?.currIndex ?? idx);
            }
        });

        ps.on('change', () => {
            // triggers when slide is switched, and at initialization
            if (ps.pswp?.currIndex == ps.pswp.options.dataSource.length - 1) {
                if (typeof endOfImagesCallback === 'function') {
                    endOfImagesCallback(ps.pswp?.currIndex, ps);
                }
            }
        });
        ps.loadAndOpen(idx, psImages);
    });
};

export const getPhotoSwapImageData = (
    images: Maybe<ImageType>[],
): PhotoSwipeImageData[] => {
    if (images === undefined) return [];

    return images.map((img: Maybe<ImageType>, i: number) => {
        if (img) {
            return {
                src: img.url,
                width: 1200,
                height: 1200,
                alt: img.description,
            } as PhotoSwipeImageData;
        } else {
            return {} as PhotoSwipeImageData;
        }
    });
};
const ImageCarouselDesktop = () => {
    const {
        selectedImage,
        carouselSelectedImageRef,
        openPhotoViewer,
        zoomEnabled,
    } = React.useContext(ImageCarouselContext);
    return (
        <BBQGuysGrid
            className={`image-carousel-container${zoomEnabled ? '' : ' no-zoom'}`}
            id="image-gallery"
            sx={{
                gridTemplateColumns: IMAGE_CAROUSEL_THUMBNAIL_SIZE + 'px 1fr',
            }}
        >
            <ImageCarouselDesktopThumbnails />
            <div
                id="selected-image"
                className="selected-image"
                ref={el =>
                    carouselSelectedImageRef !== null
                        ? (carouselSelectedImageRef.current[0] = el)
                        : () => {}
                }
                onClick={() => openPhotoViewer()}
            >
                {selectedImage && (
                    <Image
                        src={selectedImage?.url}
                        alt={selectedImage.description ?? ''}
                        width={800}
                        height={800}
                        lazyLoadImage={false}
                    />
                )}
            </div>
        </BBQGuysGrid>
    );
};

const ImageCarouselDesktopThumbnails = () => {
    const {
        item,
        images,
        selectedImageIndex,
        carouselItemsRef,
        carouselSelectedImageRef,
        loading,
        numberOfVisibleThumbnails,
        showVideoAnd360Thumbnails,
        handleSelectedImageChange,
        handleLeftClick,
        handleRightClick,
    } = React.useContext(ImageCarouselContext);
    const showArrows = images.length > numberOfVisibleThumbnails - 1;
    const maxHeightStyleValue = `calc(${IMAGE_CAROUSEL_THUMBNAIL_SIZE}px * ${numberOfVisibleThumbnails}`;
    return (
        <>
            {loading ? (
                <ImageCarouselLoading />
            ) : (
                <div
                    className={'carousel' + (showArrows ? '' : ' no_arrows')}
                    style={{ maxHeight: maxHeightStyleValue }}
                >
                    <div
                        className={'carousel__images hide-scrollbar'}
                        style={{ maxHeight: maxHeightStyleValue }}
                    >
                        {images &&
                            images.map((image, idx) => (
                                <a
                                    href={image.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={e => {
                                        e.preventDefault();
                                        handleSelectedImageChange(idx);
                                    }}
                                    onMouseOver={() =>
                                        handleSelectedImageChange(idx)
                                    }
                                    key={'image-gallery-' + idx}
                                    className={`carousel__image ${selectedImageIndex === idx && 'carousel__image-selected'}`}
                                    ref={el =>
                                        (carouselItemsRef.current[idx] = el)
                                    }
                                >
                                    <Image
                                        src={image.url}
                                        alt={
                                            image.description + ' thumbnail' ??
                                            ''
                                        }
                                        width={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                        height={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                        lazyLoadImage={false}
                                        data-pswp-width={1200}
                                        data-pswp-height={1200}
                                    />
                                </a>
                            ))}
                    </div>
                    {showArrows ? (
                        <>
                            <button
                                className="carousel__button carousel__button-left"
                                onClick={handleLeftClick}
                                aria-label="image carousel previous"
                            >
                                <KeyboardArrowUp />
                            </button>
                            <button
                                className="carousel__button carousel__button-right"
                                onClick={handleRightClick}
                                aria-label="image carousel next"
                            >
                                <KeyboardArrowDown />
                            </button>
                        </>
                    ) : null}
                    {showVideoAnd360Thumbnails && (
                        <Stack sx={{ mt: 5 }} spacing={1}>
                            {item?.hasVideo ? (
                                <ImageGalleryButton
                                    href={item?.videoUrl as string}
                                >
                                    <PlayArrow
                                        htmlColor="white"
                                        fontSize="large"
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            color: 'white',
                                            display: 'block',
                                            fontSize: '0.625rem',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Video
                                    </Box>
                                </ImageGalleryButton>
                            ) : (
                                <></>
                            )}
                            {item?.has360Image ? (
                                <Image360
                                    itemId={item.id}
                                    elementRef={carouselSelectedImageRef}
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>
                    )}
                </div>
            )}
        </>
    );
};
export default ImageCarousel;
