import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
    ProductsGridDisplay,
    ProductsGridLoading,
} from '../../Components/ProductGridItems/ProductGridItems';
import { ProductGridRefinement } from '../../Contexts/ProductGridRefinementProvider';
import { PaginatedItemsResponse, SortOption } from '../../types';
import { empty } from '../../functions';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { QL_SEARCH } from '../../Graphql/queries';

const SearchProductsGridItems = (props: {
    term: string;
    showRefinementsBar?: boolean;
    showPagination?: boolean;
    hideTitleIfNoResults?: boolean;
    hideAlertIfNoResults?: boolean;
    title?: string | null;
    withAddToCartButton?: boolean;
    departmentId?: string;
    items?: PaginatedItemsResponse;
    sortOptions?: SortOption[];
    setNoResults?: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const { page, setDisplayExploreMore, setSort } = React.useContext(
        ProductGridRefinement,
    );
    const { getPricingTierId } = React.useContext(UserAuth);
    const {
        showRefinementsBar = true,
        showPagination = true,
        title = null,
        term = '',
        hideAlertIfNoResults = false,
        hideTitleIfNoResults = true,
        withAddToCartButton = false,
        setNoResults,
    } = props;
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const searchPageParam = parseInt(searchParams.get('page') as string) || 0;
    const searchSortParam = (searchParams.get('sort') as string) || '';
    const page2 = searchPageParam > 0 ? searchPageParam : page;
    const urlQueryString = searchParams
        .toString()
        .replace('?&', '')
        .replace('?', '');

    const { data, loading } = useQuery(QL_SEARCH, {
        fetchPolicy: 'cache-and-network',
        variables: {
            term: term,
            page: page2,
            sort: searchSortParam,
            refinements: urlQueryString,
            pricingTierId: getPricingTierId(),
            deptId: props.departmentId,
        },
    });

    React.useEffect(() => {
        if (
            data !== undefined &&
            data?.searchItems &&
            data?.searchItems.countResults === 0
        ) {
            setDisplayExploreMore(true);
        }
        if (
            empty(searchSortParam) &&
            data?.searchItems?.metaData?.defaultSort
        ) {
            setSort(data?.searchItems?.metaData?.defaultSort);
        }
    }, [data]);

    if (loading) {
        return (
            <ProductsGridLoading
                showRefinementsBar={showRefinementsBar}
                showPagination={showPagination}
            />
        );
    }

    return (
        <>
            <ProductsGridDisplay
                data={props.items ?? data?.searchItems}
                sortOptions={props.sortOptions ?? data?.searchSortOptions}
                showRefinementsBar={showRefinementsBar}
                showPagination={showPagination}
                hideTitleIfNoResults={hideTitleIfNoResults}
                hideAlertIfNoResults={hideAlertIfNoResults}
                title={title}
                withAddToCartButton={withAddToCartButton}
                searchTerm={term}
                setNoResults={setNoResults}
            />
        </>
    );
};
export default function SearchProductsGrid(props: {
    term?: string;
    loading?: boolean;
    showRefinementsBar?: boolean;
    showPagination?: boolean;
    hideTitleIfNoResults?: boolean;
    hideAlertIfNoResults?: boolean;
    title?: string | null;
    withAddToCartButton?: boolean;
    items?: PaginatedItemsResponse;
    sortOptions?: SortOption[];
    departmentId?: string;
    setNoResults?: React.Dispatch<React.SetStateAction<any>>;
}) {
    if (props.loading || props.term === undefined) {
        return (
            <ProductsGridLoading
                showRefinementsBar={props.showRefinementsBar}
                showPagination={props.showPagination}
            />
        );
    }

    if (props.items !== undefined) {
        return (
            <ProductsGridDisplay
                withAddToCartButton={props.withAddToCartButton}
                showRefinementsBar={props.showRefinementsBar}
                showPagination={props.showPagination}
                hideTitleIfNoResults={props.hideTitleIfNoResults}
                hideAlertIfNoResults={props.hideAlertIfNoResults}
                title={props.title}
                data={props.items}
                sortOptions={props.sortOptions}
                setNoResults={props.setNoResults}
            />
        );
    }

    return (
        <SearchProductsGridItems
            withAddToCartButton={props.withAddToCartButton}
            term={props.term}
            showRefinementsBar={props.showRefinementsBar}
            showPagination={props.showPagination}
            hideTitleIfNoResults={props.hideTitleIfNoResults}
            hideAlertIfNoResults={props.hideAlertIfNoResults}
            title={props.title}
            items={props.items}
            sortOptions={props.sortOptions}
            departmentId={props.departmentId}
            setNoResults={props.setNoResults}
        />
    );
}
