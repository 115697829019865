import { useQuery } from '@apollo/client';
import window from 'global/window';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QL_PAGE } from '../../Graphql/queries';
import Page404 from '../../Pages/Page404/Page404';
import { PageContentLoading } from '../../Pages/PageContent/PageContent';

export default function PageLoad(props: { component: any }) {
    const Component = props.component;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const slug = pathname;
    const { data, loading, error } = useQuery(QL_PAGE, {
        variables: {
            pageURL: slug,
        },
    });

    useEffect(() => {
        if (
            typeof data?.page?.url === 'string' &&
            data.page?.url.length > 0 &&
            data.page?.url !== pathname
        ) {
            navigate(
                data.page.url +
                    (window.location.search
                        ? '?' + window.location.search
                        : ''),
                { replace: true },
            );
        }
    }, [data]);

    if (loading) {
        return <PageContentLoading />;
    }

    if (error) {
        return <Page404 />;
    }

    return <Component data={data} />;
}
