import { LoadingButton } from '@mui/lab';
import {
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Stack,
    Alert,
    Typography,
    Box,
} from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import { ResponsiveStyleValue } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { EmailWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/EmailWithLabel';
import { PhoneWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/PhoneWithLabel';
import { FieldWithLabel } from '../../Pages/Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { ContactUsSchema } from '../../Schemas/Schemas';
import theme from '../../Theme';
import { MobileDevice } from '../../Contexts/DeviceTypeProvider';
import CustomerService from './CustomerService';
import { PhoneOutlined } from '@mui/icons-material';
import { ChatBubbleOutline } from '@mui/icons-material';
import window from 'global/window';
import { gql } from '@apollo/client';
import { client } from '../../Graphql/ApolloClient';

export const QL_CONTACT_US = gql`
    mutation submitContactUs(
        $name: String!
        $email: String!
        $phone: String!
        $subject: String!
        $comment: String!
        $orderNumber: String
    ) {
        submitContactUs(
            name: $name
            email: $email
            phone: $phone
            subject: $subject
            comment: $comment
            orderNumber: $orderNumber
        ) {
            message
            success
        }
    }
`;

import { CommentFieldWithLabel } from './CommentFieldWithLabel';
import { openZendeskChat } from '../../functions';

const ContactUsForms: React.FC = () => {
    const GAP_SPACING = 1;
    const GAP_SPACING_HORIZONTAL = 2;
    const DIRECTION = { xs: 'column', md: 'row' } as ResponsiveStyleValue<
        'row' | 'row-reverse' | 'column' | 'column-reverse'
    >;
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    const FieldStyles = {
        maxWidth: '31.25rem',
    };

    const formik = useFormik({
        initialValues: {
            fullName: '',
            orderNumber: '',
            emailAddress: '',
            phoneNumber: '',
            subject: '',
            comment: '',
        },
        validationSchema: ContactUsSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            setShowSuccess(false);
            setErrorMessage(null);
            client
                .mutate({
                    mutation: QL_CONTACT_US,
                    variables: {
                        name: values.fullName,
                        email: values.emailAddress,
                        phone: values.phoneNumber,
                        subject: values.subject,
                        comment: values.comment,
                        orderNumber: values.orderNumber,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(response => {
                    if (response.data.submitContactUs.success) {
                        formik.resetForm();
                        setShowSuccess(true);
                    } else {
                        setErrorMessage(response.data.submitContactUs.message);
                    }
                })
                .catch(err => {
                    setErrorMessage(err.errors[0].message);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        },
    });
    const contactButtonStyle = {
        width: { xs: '100%', md: '49%' },
        justifyContent: 'flex-start',
        borderColor: `${theme.palette.grey[600]}`,
        '&:hover': {
            borderColor: `${theme.palette.grey[600]}`,
            '& .MuiSvgIcon-root, & .MuiTypography-root:not(.available-text)': {
                color: theme.palette.primary.main,
            },
        },
    };

    const iconStyle = {
        mr: 2,
        opacity: '0.6',
        fontSize: 40,
        color: theme.palette.text.secondary,
    };

    const textStyle = {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        color: theme.palette.text.secondary,
    };
    const handlePhoneClick = () => {
        window.location.href = 'tel:18777432269';
    };
    const handleClickChat = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        openZendeskChat();
    };

    const subjectSelectRef = React.useRef<HTMLSelectElement>(null);

    const handleSubjectLabelClick = () => {
        if (subjectSelectRef.current) {
            subjectSelectRef.current?.focus();
        }
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack gap={GAP_SPACING}>
                <LoadingButton
                    variant="outlined"
                    sx={{ ...contactButtonStyle, ...FieldStyles }}
                    onClick={handlePhoneClick}
                >
                    <Box display="flex" alignItems="center">
                        <PhoneOutlined sx={iconStyle} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Typography component="span" sx={textStyle}>
                                1-877-743-2269
                            </Typography>
                            <Typography
                                component="span"
                                className="available-text"
                                sx={{
                                    fontStyle: 'italic !important',
                                    display: 'block',
                                    color: theme.palette.brand.brightgreen,
                                }}
                            >
                                {false && 'Available'}
                            </Typography>
                        </Box>
                    </Box>
                </LoadingButton>
                <LoadingButton
                    variant="outlined"
                    sx={{ ...contactButtonStyle, ...FieldStyles }}
                    onClick={handleClickChat}
                >
                    <Box display="flex" alignItems="center">
                        <ChatBubbleOutline sx={iconStyle} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Typography component="span" sx={textStyle}>
                                Live Chat
                            </Typography>
                            <Typography
                                component="span"
                                sx={{
                                    fontStyle: 'italic !important',
                                    display: 'block',
                                    color: theme.palette.text.secondary,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {false && 'Sorry, we&#39;re not available'}
                            </Typography>
                        </Box>
                    </Box>
                </LoadingButton>
                <MobileDevice>
                    <CustomerService />
                </MobileDevice>
                <Typography variant="h4" component="h2" sx={{ mt: 5 }}>
                    <span
                        style={{
                            display: 'flex',
                        }}
                    >
                        <EmailOutlined sx={{ mr: 1, opacity: '0.6' }} />
                        Message Us
                    </span>
                </Typography>
                {errorMessage !== null && (
                    <Alert severity="error">{errorMessage}</Alert>
                )}
                <Stack
                    direction={DIRECTION}
                    gap={GAP_SPACING_HORIZONTAL}
                    sx={{ width: { xs: '100%', md: '49%' } }}
                >
                    <FormControl
                        variant="standard"
                        disabled={formik.isSubmitting}
                        fullWidth
                        sx={FieldStyles}
                    >
                        <FormLabel
                            htmlFor="subject"
                            onClick={handleSubjectLabelClick}
                            sx={{ cursor: 'pointer' }}
                        >
                            <span style={{ color: theme.palette.error.main }}>
                                *
                            </span>
                            Subject
                        </FormLabel>

                        <Select
                            inputProps={{ id: 'subject' }}
                            variant="outlined"
                            fullWidth
                            id="subject"
                            name="subject"
                            required
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.subject &&
                                Boolean(formik.errors.subject)
                            }
                            displayEmpty
                            inputRef={subjectSelectRef}
                        >
                            <MenuItem value="">
                                <em>Choose an option...</em>
                            </MenuItem>
                            <MenuItem value="Product Questions / Availability">
                                Product Questions / Availability
                            </MenuItem>
                            <MenuItem value="Request Design Services">
                                Request Design Services
                            </MenuItem>
                            <MenuItem value="Use Rewards Points">
                                Use Rewards Points
                            </MenuItem>
                            <MenuItem value="Payments / Checkout Assistance">
                                Payments / Checkout Assistance
                            </MenuItem>
                            <MenuItem value="PRO Account Questions">
                                PRO Account Questions
                            </MenuItem>
                            <MenuItem value="Order Status">
                                Order Status
                            </MenuItem>
                            <MenuItem value="Delivery Issues">
                                Delivery Issues
                            </MenuItem>
                            <MenuItem value="Damaged / Missing Items">
                                Damaged / Missing Items
                            </MenuItem>
                            <MenuItem value="Cancel / Modify Order">
                                Cancel / Modify Order
                            </MenuItem>
                            <MenuItem value="Returns Assistance">
                                Returns Assistance
                            </MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={DIRECTION} gap={GAP_SPACING_HORIZONTAL}>
                    <FieldWithLabel
                        id="fullName"
                        name="fullName"
                        formik={formik}
                        label={`Full Name`}
                        required={true}
                        sx={FieldStyles}
                    />
                    <FieldWithLabel
                        id="orderNumber"
                        name="orderNumber"
                        formik={formik}
                        label={`Order Number (optional)`}
                        required={false}
                        sx={FieldStyles}
                    />
                </Stack>

                <Stack direction={DIRECTION} gap={GAP_SPACING_HORIZONTAL}>
                    <EmailWithLabel
                        id="emailAddress"
                        name="emailAddress"
                        formik={formik}
                        label={`Email Address`}
                        required={true}
                        sx={FieldStyles}
                    />
                    <PhoneWithLabel
                        id="phoneNumber"
                        name="phoneNumber"
                        formik={formik}
                        label={`Phone Number`}
                        required={true}
                        sx={FieldStyles}
                        InputProps={{
                            placeholder: '(____) ____-______',
                        }}
                    />
                </Stack>
                <CommentFieldWithLabel
                    sx={{ mb: 3 }}
                    id="comment"
                    name="comment"
                    formik={formik}
                    label={'What can we help with?'}
                    required={true}
                />
            </Stack>
            {showSuccess && (
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    Thanks for contacting us. One of our experts will be in
                    touch with you soon. Have a great day!
                </Alert>
            )}
            <LoadingButton
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ width: { xs: '100%', md: 'fit-content' } }}
            >
                Send Message
            </LoadingButton>
        </form>
    );
};

export default ContactUsForms;
