import {
    CalculateTwoTone,
    Cancel,
    ChatBubbleOutline,
    EmailOutlined,
    PhoneOutlined,
} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import {
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Breakpoint,
    Button,
    ButtonProps,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    CheckboxProps,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Popover as MaterialPopover,
    Select as MaterialSelect,
    Paper,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    SvgIconProps,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import { Box, BoxProps } from '@mui/system';
import window from 'global/window';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { SiteContext } from '../../Contexts/SiteProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
    isMobile,
} from '../../Contexts/DeviceTypeProvider';
import { client } from '../../Graphql/ApolloClient';
import { QL_CONTENT_BY_ID } from '../../Graphql/queries';
import { LoginForm } from '../../Pages/Authentication/PageLogin/LoginForm';
import { RegistrationForm } from '../../Pages/Authentication/PageLogin/RegistrationForm';
import { RenderContentData } from '../../Pages/PageContent/PageContent';
import theme, { PRIMARY_FONT_FAMILY } from '../../Theme';
import { getEnvironmentVariable } from '../../config';
import { empty, openZendeskChat, randomNumber, slugify } from '../../functions';
import {
    Contact,
    Content,
    ContentLinks,
    Department,
    Item,
    Maybe,
} from '../../types';
import { Image } from '../Image';
import { screenReaderPrice } from '../Item/Pricing';
import { BBQGuysImageCard } from './BBQGuysImageCard';
import Modal, {
    BBQGuysBottomDrawer,
    BBQGuysModalTitle,
    CloseIconButton,
} from './BBQGuysModal';
import {
    Accordion,
    CheckboxCheckedIcon,
    CheckboxIcon,
    ChildDepartmentLinkLevel1,
    ChildDepartmentLinkLevel2,
    HelpTipIconStyled,
    Label,
    ShowMoreButton,
    ShowMoreContainer,
    SidebarList,
    SidebarListItem,
} from './BBQGuysStyledComponents';
import { FuelTypeNaturalGas, FuelTypePropane } from './icons/icons';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { useMediaQuery, useTheme } from '@mui/material';
import ScContainer from '../Layout/ScContainer/ScContainer';

export const ScreenReaderOnly = ({ children }: { children: any }) => {
    return <span className="sr-only">{children}</span>;
};

export const ScreenReaderIgnore = ({
    sx,
    children,
}: {
    sx?: SxProps;
    children: React.ReactNode;
}) => {
    return (
        <Box
            component="span"
            className="sr-ignore"
            aria-hidden="true"
            sx={{ ...sx }}
        >
            {children}
        </Box>
    );
};

const SelectLabelValueDisplay = (props: {
    label: string;
    value: string | React.ReactNode;
    grouped?: boolean;
    groupIndex?: number;
    groupSize?: number;
    displayColon?: boolean;
}) => {
    const {
        label,
        value,
        grouped = false,
        groupIndex = 0,
        displayColon = true,
    } = props;
    return (
        <Box
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.background.default,
                paddingRight: isMobile ? '25px !important' : '',
                [theme.breakpoints.down('md')]: {
                    border: theme.palette.border,
                    ...(grouped && groupIndex > 0
                        ? {
                              borderTopWidth: 0,
                          }
                        : {}),
                    borderRadius: 0,
                    padding: theme.spacing(1),
                },
            }}
        >
            {label && label.length > 0 && (
                <Box
                    component="label"
                    htmlFor={slugify(label)}
                    sx={{ color: theme.palette.info.dark }}
                >
                    {label}
                </Box>
            )}
            {displayColon && ':'}
            <Box
                component="span"
                sx={{
                    marginLeft: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'bottom',
                }}
            >
                {value}
            </Box>
        </Box>
    );
};

const CloseModalButton = styled(CloseIconButton)(({ theme }) => ({
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
}));

export const MobileCloseIcon = (props: { onClose: () => void }) => {
    return (
        <CloseModalButton
            size="medium"
            aria-label="close"
            onClick={() => props.onClose()}
        >
            <CloseIcon />
        </CloseModalButton>
    );
};

interface BBQGuysBottomDrawerWrapperProps {
    open: boolean;
    label?: string;
    title?: string;
    onClose: (type: 'cancel' | 'confirm') => void;
    children: React.ReactNode;
}

export const BBQGuysBottomDrawerWrapper: React.FC<
    BBQGuysBottomDrawerWrapperProps
> = ({ open, label, title, onClose, children }) => {
    return (
        <BBQGuysBottomDrawer
            open={open}
            onClose={() => onClose('cancel')}
            sx={{
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px',
                height: '62vh',
                overflow: 'hidden',
                padding: 0,
            }}
        >
            {title && <BBQGuysModalTitle>{title}</BBQGuysModalTitle>}
            <MobileCloseIcon onClose={() => onClose('cancel')} />
            <FormControl>
                <Box
                    sx={{
                        padding: '12px 24px',
                    }}
                >
                    {label && (
                        <FormLabel id={`radio-${label}-group-label`}>
                            {label}
                        </FormLabel>
                    )}
                    <Box
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            maxHeight: '275px',
                            minHeight: '56px',
                        }}
                    >
                        {children}
                    </Box>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                            width: '100%',
                            marginTop: '20px',
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                        }}
                        onClick={() => onClose('confirm')}
                    >
                        Confirm
                    </Button>
                </Box>
            </FormControl>
        </BBQGuysBottomDrawer>
    );
};

const MobileSelect = (props: any) => {
    const {
        label,
        value,
        grouped = false,
        groupIndex = 0,
        groupSize = 0,
        onChange,
        sx = {},
        title,
        onClose,
        ...other
    } = props;
    const [showDrawer, setShowDrawer] = React.useState(false);
    /* sends status back to the parent */
    const handleClose = (type: 'cancel' | 'confirm') => {
        onClose(type);
        setShowDrawer(false);
    };

    let displayValue = props.renderValue ? props.renderValue(value) : value;
    other.children.forEach((v: any) => {
        if (
            v.props?.value &&
            v.props.value === value &&
            typeof v.props.children === 'string'
        ) {
            displayValue = v.props.children;
        }
    });
    return (
        <>
            <Box
                onClick={e => setShowDrawer(true)}
                sx={{
                    textAlign: 'left',
                    overflow: 'hidden',
                    position: 'relative',
                    ...sx,
                }}
            >
                <SelectLabelValueDisplay
                    label={label}
                    value={
                        typeof other.renderValue === 'function'
                            ? other.renderValue(displayValue)
                            : displayValue
                    }
                    grouped={grouped}
                    groupIndex={groupIndex}
                    groupSize={groupSize}
                />
                <ExpandMoreIcon
                    style={{
                        position: 'absolute',
                        right: '0px',
                        top: '7px',
                        transition: 'transform 0.3s, color 0.3s',
                        transform: showDrawer ? 'rotate(180deg)' : '',
                        color: showDrawer ? '#babec1' : '',
                        pointerEvents: 'none',
                    }}
                />
            </Box>
            <BBQGuysBottomDrawerWrapper
                open={showDrawer}
                label={label}
                title={title}
                onClose={handleClose}
            >
                <RadioGroup
                    aria-labelledby={`radio-${label}-group-label`}
                    defaultValue={value}
                    defaultChecked={value}
                    value={value}
                    name={`radio-${label}-group`}
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        value2: string,
                    ) => {
                        if (typeof onChange === 'function') {
                            setTimeout(() => {
                                onChange(event, value2);
                            }, 50);
                        }
                    }}
                >
                    {props.children.map(
                        (elem: React.ReactElement<any, any>, i: number) => {
                            return (
                                <FormControlLabel
                                    key={label + i}
                                    label={elem}
                                    control={
                                        <Radio
                                            value={elem.props?.value ?? value}
                                        />
                                    }
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 7fr',
                                        marginRight: 0,
                                    }}
                                />
                            );
                        },
                    )}
                </RadioGroup>
            </BBQGuysBottomDrawerWrapper>
        </>
    );
};

export const Select = (props: any) => {
    const {
        label,
        grouped = false,
        groupIndex = 0,
        groupSize = 0,
        sx = {},
        title,
        displayColon = true,
        ...other
    } = props;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const selectedItemRef = React.useRef<HTMLLIElement | null>(null);
    delete other.label;
    delete other.labelId;

    // allows focus of the default item selected
    React.useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                selectedItemRef.current?.focus();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    return (
        <>
            <DesktopAndTabletDevice>
                <MaterialSelect
                    {...other}
                    sx={{
                        backgroundColor: `${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.divider}`,
                        borderTopLeftRadius:
                            grouped && groupIndex == 0
                                ? theme.shape.borderRadius
                                : 0,
                        borderTopRightRadius:
                            grouped && groupIndex == 0
                                ? theme.shape.borderRadius
                                : 0,
                        borderBottomLeftRadius:
                            grouped && groupSize == 0
                                ? theme.shape.borderRadius
                                : 0,
                        borderBottomRightRadius:
                            grouped && groupSize == 0
                                ? theme.shape.borderRadius
                                : 0,
                        height: '2.625rem',
                        overflow: 'hidden',
                        ...(grouped && groupIndex > 0
                            ? {
                                  borderTopWidth: 0,
                              }
                            : {}),
                        '&:before': {
                            border: 0,
                        },
                        '&:hover': {
                            '&:before': {
                                border: '0 !important',
                            },
                        },
                        ...sx,
                    }}
                    variant="standard"
                    SelectDisplayProps={{
                        name: slugify(label),
                        sx: {
                            padding: theme.spacing(3, 8, 3, 3),
                        },
                    }}
                    inputProps={{
                        role: 'select',
                        id: slugify(label),
                    }}
                    renderValue={(value: any) => {
                        other.children.forEach((v: any) => {
                            if (
                                v.props?.value &&
                                v.props.value === value &&
                                typeof v.props.children === 'string'
                            ) {
                                value = v.props.children;
                            }
                        });
                        return (
                            <SelectLabelValueDisplay
                                label={label}
                                displayColon={displayColon}
                                value={
                                    typeof other.renderValue === 'function'
                                        ? other.renderValue(value)
                                        : value
                                }
                            />
                        );
                    }}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    IconComponent={() => (
                        <ExpandMoreIcon
                            style={{
                                position: 'absolute',
                                right: '0px',
                                transition: 'transform 0.3s, color 0.3s',
                                transform: isOpen ? 'rotate(180deg)' : '',
                                color: isOpen ? '#babec1' : '',
                                pointerEvents: 'none',
                            }}
                        />
                    )}
                    MenuProps={{
                        variant: 'menu',
                        transitionDuration: 0,
                        elevation: 0,
                        PaperProps: {
                            style: {
                                border: `1px solid ${theme.palette.divider}`,
                                borderTopWidth: 0,
                                borderTopRightRadius: 0,
                                borderTopLeftRadius: 0,
                                marginTop: -1,
                                left: -1000,
                                top: -1000,
                            },
                            ref: selectedItemRef,
                        },
                        //setting variant to menu makes it appear below the element
                    }}
                >
                    {props.children}
                </MaterialSelect>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <MobileSelect
                    label={label}
                    grouped={grouped}
                    groupIndex={groupIndex}
                    groupSize={groupSize}
                    sx={sx}
                    title={title ?? label}
                    displayColon={displayColon}
                    onClose={() => setIsOpen(false)}
                    {...other}
                />
            </MobileDevice>
        </>
    );
};

export const DL = (props: any) => {
    const { sx, ...otherProps } = props;
    return (
        <Box
            component="dl"
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                margin: 0,
                alignItems: 'center',
                ...sx,
            }}
            {...otherProps}
        >
            {props.children}
        </Box>
    );
};

export const DT = (props: any) => {
    const { sx, ...otherProps } = props;
    return (
        <Box component="dt" sx={{ ...sx }} {...otherProps}>
            {props.children}
        </Box>
    );
};

export const DD = (props: any) => {
    const { sx, ...otherProps } = props;
    return (
        <Box
            component="dd"
            sx={{ flexGrow: 1, marginLeft: '1rem', ...sx }}
            {...otherProps}
        >
            {props.children}
        </Box>
    );
};

export const RewardPointAmount = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <Typography variant="bold" color="secondary" component="strong">
            {children}
        </Typography>
    );
};
interface CheckboxWithLabelProps extends CheckboxProps {
    selectable?: boolean;
    label: (() => string) | string | React.ReactNode;
    checked?: boolean;
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => void;
    labelVariant?: 'text' | 'link';
    loading?: boolean;
    formSx?: SxProps;
    sx?: SxProps;
    alignTop?: boolean;
}

export const CheckboxWithLabel = (props: CheckboxWithLabelProps) => {
    const {
        selectable = true,
        label,
        checked,
        onChange,
        labelVariant = 'text',
        loading = false,
        formSx = {},
        sx = {},
        alignTop = false,
        ...other
    } = props;

    if (empty(label)) {
        return null;
    }
    const ariaLabelPrice = (facetLabel: any) => {
        if (typeof facetLabel === 'string') {
            if (facetLabel.indexOf('$') === 0) {
                return facetLabel
                    .split('-')
                    .map((p: string) => screenReaderPrice(p).replace(';', ''))
                    .join(' to ');
            }
            return facetLabel;
        }
        return 'Checkbox option';
    };
    const labelText = typeof label == 'function' ? label() : label;
    let id = slugify(ariaLabelPrice(labelText));
    if (empty(id)) {
        id = 'cb-' + randomNumber(1, 1000);
    }
    return (
        <FormGroup sx={{ ...formSx }}>
            <Label
                aria-label={ariaLabelPrice(labelText)}
                sx={{
                    ...sx,
                    color:
                        labelVariant === 'link'
                            ? `${theme.typography.linkColor.color}`
                            : 'inherit',
                    ...(alignTop && {
                        display: 'flex',
                        alignItems: 'normal',
                    }),
                }}
                control={
                    selectable === true ? (
                        <Checkbox
                            id={id}
                            name={id}
                            checked={checked}
                            onChange={onChange}
                            sx={{
                                ...(alignTop && {
                                    marginTop: -1,
                                    padding: '0 1 0 0',
                                }),
                            }}
                            //sx={{
                            //  '&:hover': { bgcolor: 'transparent' },
                            //}}
                            //disableRipple
                            color="primary"
                            checkedIcon={<CheckboxCheckedIcon />}
                            icon={
                                loading ? (
                                    <LoadingSpinner
                                        progressProps={{ size: 16 }}
                                    />
                                ) : (
                                    <CheckboxIcon />
                                )
                            }
                            {...other}
                        />
                    ) : (
                        <Checkbox
                            id={id}
                            name={id}
                            disabled
                            checked={checked}
                            onChange={onChange}
                            sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                ...(alignTop && {
                                    marginTop: -1,
                                    padding: '0 1 0 0',
                                }),
                            }}
                            disableRipple
                            color="primary"
                            checkedIcon={
                                <CheckboxCheckedIcon sx={{ opacity: '0.6' }} />
                            }
                            icon={
                                loading ? (
                                    <LoadingSpinner
                                        progressProps={{ size: 16 }}
                                    />
                                ) : (
                                    <CheckboxIcon sx={{ opacity: '0.6' }} />
                                )
                            }
                            {...other}
                        />
                    )
                }
                label={
                    <Box
                        sx={{
                            ...(alignTop && {
                                paddingTop: 1.5,
                            }),
                            display: 'inline-block',
                        }}
                    >
                        {labelText}
                    </Box>
                }
            />
        </FormGroup>
    );
};

interface LineEllipsisProps extends BoxProps {
    text: string;
    maxLines?: number;
    marginLeft?: string;
}

export const LineEllipsis = (props: LineEllipsisProps) => {
    const { text, maxLines = 2, marginLeft = '', ...rest } = props;

    return (
        <Box
            {...rest}
            sx={{
                WebkitLineClamp: maxLines + '',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: '24px',
                marginLeft: marginLeft,
            }}
        >
            {text}
        </Box>
    );
};

const PopoverButtonContent = (props: {
    buttonText?: any;
    buttonTitle?: string | any;
    buttonChevron?: boolean;
    buttonTitleProps?: object;
    buttonTextProps?: object;
    buttonChevronProps?: object;
    hasChildren?: boolean;
    anchorEl?: null | HTMLElement;
}) => {
    const {
        buttonText = null,
        buttonTitle = null,
        buttonTextProps = {},
        buttonTitleProps = {},
        buttonChevronProps = {},
        hasChildren = false,
        anchorEl = null,
        buttonChevron = false,
    } = props;
    return buttonText ? (
        <>
            <Stack
                direction="row"
                sx={{ m: 0, display: 'inline-flex', flexDirection: 'column' }}
            >
                {buttonTitle ? (
                    <Box {...buttonTitleProps}>{buttonTitle}</Box>
                ) : null}
                <Box
                    component="span"
                    sx={{ display: 'block', whiteSpace: 'nowrap' }}
                >
                    <Box sx={{ display: 'inline-block', py: 0, pr: 0 }}>
                        <Stack
                            direction="row"
                            sx={{ m: 0, alignItems: 'center' }}
                        >
                            <Typography
                                variant="body1"
                                component="span"
                                {...buttonTextProps}
                            >
                                {buttonText}
                            </Typography>
                            {buttonChevron && hasChildren ? (
                                anchorEl !== null ? (
                                    <KeyboardArrowUp
                                        sx={{ height: '1rem' }}
                                        {...buttonChevronProps}
                                    />
                                ) : (
                                    <KeyboardArrowDown
                                        sx={{
                                            height: '1rem',
                                            position: 'relative',
                                            zIndex: 9,
                                        }}
                                        {...buttonChevronProps}
                                    />
                                )
                            ) : null}
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </>
    ) : null;
};

export const Popover = (props: {
    title: string;
    children?: any;
    buttonText?: any;
    buttonTitle?: string | any;
    buttonIcon?: string | any;
    buttonChevron?: boolean;
    open?: boolean;
    anchorElement?: null | HTMLElement;
    buttonProps?: ButtonProps;
    buttonIconSxProps?: SxProps;
    buttonTitleProps?: object;
    buttonTextProps?: object;
    origin?: {
        vertical: 'bottom' | 'top' | 'center';
        horizontal: 'left' | 'right' | 'center';
    };
    buttonChevronProps?: object;
    width?: number;
    closeButton?: boolean;
    onClick?: () => void;
}) => {
    const {
        anchorElement = null,
        buttonText = '',
        buttonTitle = undefined,
        buttonIcon = undefined,
        buttonProps = {},
        buttonChevron = false,
        buttonTitleProps = {},
        buttonIconSxProps = {},
        buttonTextProps = {},
        origin = null,
        buttonChevronProps = {},
        closeButton = true,
        width = null,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
        anchorElement,
    );
    const open = props.open || Boolean(anchorEl);

    const location = useLocation();

    React.useEffect(() => {
        setAnchorEl(null);
    }, []);
    React.useEffect(() => {
        handleClose();
    }, [location]);

    const handleClick = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.MouseEvent<HTMLDivElement>,
    ) => {
        setAnchorEl(event.currentTarget || null);
        if (typeof props.onClick == 'function') {
            props.onClick();
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const PopoverCloseButton = () => {
        return closeButton ? (
            <IconButton
                onClick={handleClose}
                sx={{
                    color: (theme: Theme) => theme.palette.primary.main,
                    position: 'absolute',
                    right: (theme: Theme) => theme.spacing(1),
                    top: (theme: Theme) => theme.spacing(0.75),
                    ':hover': {
                        color: (theme: Theme) => theme.palette.primary.light,
                    },
                }}
                aria-label="Close"
            >
                <Cancel />
            </IconButton>
        ) : (
            <></>
        );
    };

    const { sx: buttonSx, ...buttonRest } = buttonProps;
    return (
        <>
            {buttonIcon ? (
                <IconButton
                    onClick={handleClick}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mx: 'auto',
                        ':hover': {
                            color: (theme: Theme) =>
                                theme.palette.secondary.main,
                            textDecoration: 'none',
                            span: {
                                color: (theme: Theme) =>
                                    theme.palette.primary.light,
                            },
                        },
                        ...buttonIconSxProps,
                    }}
                >
                    {buttonIcon}
                    <PopoverButtonContent
                        buttonText={buttonText}
                        buttonTitle={buttonTitle}
                        buttonTextProps={buttonTextProps}
                        buttonTitleProps={buttonTitleProps}
                        buttonChevronProps={buttonChevronProps}
                        hasChildren={props.children !== null}
                        anchorEl={anchorEl}
                        buttonChevron={buttonChevron}
                    />
                </IconButton>
            ) : (
                <Button
                    onClick={handleClick}
                    sx={{
                        ':hover': {
                            color: (theme: Theme) =>
                                theme.palette.secondary.main,
                            textDecoration: 'none',
                            span: {
                                color: (theme: Theme) =>
                                    theme.palette.primary.light,
                            },
                        },
                        ...buttonSx,
                    }}
                    {...buttonRest}
                >
                    <PopoverButtonContent
                        buttonText={buttonText}
                        buttonTitle={buttonTitle}
                        buttonTextProps={buttonTextProps}
                        buttonTitleProps={buttonTitleProps}
                        buttonChevronProps={buttonChevronProps}
                        hasChildren={props.children !== null}
                        anchorEl={anchorEl}
                        buttonChevron={buttonChevron}
                    />
                </Button>
            )}

            {props.children && (
                <>
                    <MobileDevice>
                        <BBQGuysBottomDrawer open={open} onClose={handleClose}>
                            <Typography
                                variant="popoverTitle"
                                sx={{
                                    m: '-1.5rem -1.5rem 0 -1.5rem',
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                }}
                            >
                                {props.title}
                            </Typography>
                            <Box p={2.5}>{props.children}</Box>
                            {/* placing close button at bottom of popover for screen readers so they read it last instead of first */}
                            <PopoverCloseButton />
                        </BBQGuysBottomDrawer>
                    </MobileDevice>
                    <DesktopAndTabletDevice>
                        <MaterialPopover
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    overflow: 'visible',
                                    borderRadius: 0,
                                    maxWidth: 500,
                                    border: 0,
                                    boxShadow:
                                        '0 0 0 0.0625em rgb(0 0 0 / 10%), 0 0.125em 0.25em rgb(0 0 0 / 13%), 0 3em 3em rgb(0 0 0 / 30%)',
                                },
                            }}
                            elevation={0}
                            anchorReference="anchorEl"
                            anchorEl={anchorEl}
                            anchorOrigin={
                                origin === null
                                    ? { vertical: 'bottom', horizontal: 'left' }
                                    : origin
                            }
                            aria-label={props.title + ' modal'}
                            tabIndex={0}
                        >
                            <Box className="popover top" sx={{ width: width }}>
                                <span className="arrow"></span>
                                <Typography variant="popoverTitle">
                                    {props.title}
                                </Typography>
                                <Box p={2}>{props.children}</Box>
                            </Box>
                            {/* placing close button at bottom of popover for screen readers so they read it last instead of first */}
                            <PopoverCloseButton />
                        </MaterialPopover>
                    </DesktopAndTabletDevice>
                </>
            )}
        </>
    );
};

export const BulletedList = (props: {
    bullets: (string | any)[];
    sx?: SxProps;
}) => {
    const { bullets, sx = {} } = props;
    return (
        <List className="bullets" sx={{ listStyleType: 'disc', pl: 3, ...sx }}>
            {bullets.map((bullet: string | any, i: number) => {
                return (
                    <ListItem
                        key={i}
                        sx={{ display: 'list-item', mb: 1, p: 0 }}
                    >
                        {bullet}
                    </ListItem>
                );
            })}
        </List>
    );
};

export const Section = (props: {
    className?: string;
    title?: string;
    children: React.ReactNode;
    withTopBorder?: boolean;
    anchor?: string;
    expandable?: boolean;
    open?: boolean;
    initialHeight?: number;
    onOpen?: () => void;
    onClose?: () => void;
    numberOfElements?: number;
    elementsPerRow?: number;
    alignTitle?: 'left' | 'right' | 'center';
    id?: string;
    sx?: SxProps;
}) => {
    const {
        className,
        title,
        children,
        withTopBorder = true,
        expandable = false,
        initialHeight = 400,
        numberOfElements = 5,
        elementsPerRow = 4,
        sx = {},
        alignTitle = 'left',
    } = props;
    const { open = false } = props;
    const optionalProps = { id: props.id };
    const [hasBeenManuallyExpanded, setHasBeenManuallyExpanded] =
        React.useState(false);
    const [expanded, setExpanded] = React.useState(open);
    const isExpandable = expandable && numberOfElements > elementsPerRow;
    const elemRef = React.useRef<HTMLDivElement>(null);

    const handleShowMore = () => {
        if (isExpandable && !expanded && typeof props.onOpen === 'function') {
            props.onOpen();
        }
        if (isExpandable && expanded && typeof props.onClose === 'function') {
            props.onClose();
        }
        if (isExpandable && expanded && elemRef.current) {
            elemRef.current.scrollIntoView();
        }
        setHasBeenManuallyExpanded(!hasBeenManuallyExpanded);
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        if (!hasBeenManuallyExpanded && open) {
            handleShowMore();
        }
    }, [open]);

    const getHeight = () => {
        return isExpandable && !hasBeenManuallyExpanded
            ? initialHeight
            : 'auto';
    };
    const sxData: SxProps = {
        pt: 1,
        pb: 6,
        borderTop: withTopBorder ? theme.palette.border : '0',
        height: getHeight(),
        overflowY: isExpandable ? 'hidden' : 'visible',
    };
    return (
        <>
            <Box
                className={className}
                {...optionalProps}
                sx={{ ...sxData, ...sx }}
                ref={elemRef}
            >
                {typeof props.anchor == 'string' && props.anchor.length > 0 && (
                    <a id={props.anchor} />
                )}
                {title && (
                    <Typography
                        variant="sectionTitle"
                        component="h2"
                        sx={{ display: 'block', mb: 0, textAlign: alignTitle }}
                    >
                        {title}
                    </Typography>
                )}
                {children}
            </Box>
            {isExpandable ? (
                <ShowMoreContainer
                    sx={{
                        backgroundImage: !expanded ? 'transparent' : 'inherit',
                    }}
                >
                    <ShowMoreButton
                        size="small"
                        variant="standard"
                        onClick={handleShowMore}
                    >
                        {'Show ' + (expanded ? 'Less' : 'More')}
                    </ShowMoreButton>
                </ShowMoreContainer>
            ) : null}
        </>
    );
};

export const HelpTipIcon = (props: {
    contentId?: string | Maybe<number>;
    title?: string;
    fontSize?: number;
    withPaddingLeft?: boolean;
    size?: Breakpoint | false;
    sx?: SxProps;
}) => {
    const {
        contentId = 0,
        fontSize = 18,
        withPaddingLeft = true,
        title = '',
        size = 'md',
        sx = {},
    } = props;
    const contentIdStr = contentId as string;

    if (parseInt(contentIdStr) > 0) {
        return (
            <ScreenReaderIgnore
                sx={{ pr: 0.5, pl: withPaddingLeft ? 0.5 : 0, ...sx }}
            >
                <ContentModalButton
                    contentId={contentIdStr}
                    title={title}
                    size={size}
                    button={
                        <IconButton
                            aria-label={
                                'Learn more ' + (title && 'about ' + title)
                            }
                            tabIndex={0}
                        >
                            <HelpTipIconStyled sx={{ fontSize: fontSize }} />
                        </IconButton>
                    }
                />
            </ScreenReaderIgnore>
        );
    }
    return <></>;
};

export const loadContent = async (contentId: string): Promise<Content> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const response = await client.query({
                query: QL_CONTENT_BY_ID,
                variables: {
                    id: contentId,
                },
            });

            if (response.error) {
                reject(response.error);
            }

            if (!response.loading) {
                resolve(response.data.content);
            }
        })();
    });
};

export const ContentModalButton = React.memo(
    function ContentModalButton(props: {
        contentId?: string | number;
        button: any;
        content?: Content;
        size?: Breakpoint | false;
        title?: string | undefined;
        dialogContentProps?: DialogContentProps;
    }) {
        const {
            contentId = null,
            size = 'lg',
            title = '',
            dialogContentProps = {},
        } = props;
        let { button } = props;

        const [open, setOpen] = React.useState(false);
        const [loading, setLoading] = React.useState(false);
        const [content, setContent] = React.useState(
            (props.content || {}) as Content,
        );

        const handleClickOpen = () => {
            setOpen(true);
            setLoading(true);
            if (contentId) {
                loadContent(`${contentId}`)
                    .then((content: Content) => {
                        setContent(content);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        };

        const handleClose = () => {
            setOpen(false);
        };

        button = React.cloneElement(button, {
            onClick: () => handleClickOpen(),
            'data-content-id': contentId,
        });

        const descriptionElementRef = React.useRef<HTMLElement>(null);
        React.useEffect(() => {
            if (open) {
                const { current: descriptionElement } = descriptionElementRef;
                if (descriptionElement !== null) {
                    descriptionElement.focus();
                }
            }
        }, [open]);

        if (contentId === '0' || contentId === null || contentId === 0) {
            return <></>;
        }
        return (
            <>
                {button}
                {content && (
                    <Modal
                        open={open}
                        handleClose={handleClose}
                        scroll={'paper'}
                        size={size}
                        title={title}
                        loading={content === undefined}
                    >
                        {loading ? (
                            <LoadingSpinner center />
                        ) : (
                            <>
                                {content !== undefined &&
                                content.id !== undefined ? (
                                    <DialogContent
                                        dividers={false}
                                        ref={descriptionElementRef}
                                        {...dialogContentProps}
                                    >
                                        <RenderContentData content={content} />
                                    </DialogContent>
                                ) : null}
                            </>
                        )}
                    </Modal>
                )}
            </>
        );
    },
);

export const LoadingSpinner = (props: {
    progressProps?: any;
    center?: boolean;
    sx?: SxProps;
}) => {
    const { center = false, sx = {} } = props;
    return (
        <Box
            aria-busy="true"
            aria-live="polite"
            sx={{
                display: 'flex',
                justifyContent: center ? 'center' : 'left',
                ...sx,
            }}
        >
            <CircularProgress {...props.progressProps} />
        </Box>
    );
};

export const TrustBadges = (props: {
    showHonorRole?: boolean;
    showSecure?: boolean;
    showBusinessOfWeek?: boolean;
}) => {
    const {
        showHonorRole = true,
        showSecure = true,
        showBusinessOfWeek = true,
    } = props;
    return (
        <div className="trust-badges center">
            {showHonorRole && (
                <Link
                    sx={{ display: 'flex', m: 2 }}
                    href="https://www.inc.com/profile/shopperschoicecom-dba-bbqguys"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Inc 5000 Honor Roll Twelve-Time Honoree"
                        width="64"
                        height="64"
                        data-src="https://cdn.shocho.co/sc-site/corp/HonorRoll_TenTime.jpg"
                        src="https://cdn.shocho.co/sc-site/corp/HonorRoll_TenTime.jpg"
                    />
                </Link>
            )}

            {showSecure && (
                <ContentModalButton
                    contentId="21306"
                    button={
                        <Button
                            variant="outlined"
                            color="success"
                            sx={{ display: 'flex', m: 2, p: 0 }}
                        >
                            <img
                                alt="100% Protected Safe and Secure Shopping"
                                width="150"
                                height="50"
                                src="https://cdn.shocho.co/sc-site/logo-badges/trust-badge--secure.svg"
                            />
                            <span className="visually-hidden">
                                100% Protected Safe &amp; Secure
                            </span>
                        </Button>
                    }
                />
            )}
            {showBusinessOfWeek && (
                <Link
                    sx={{ display: 'flex', m: 2 }}
                    href="https://www.kennedy.senate.gov/public/2019/4/sen-kennedy-applauds-louisiana-business-selected-as-senate-small-business-of-the-week"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="United States Senate Committee Business of the Week 2019"
                        width="64"
                        height="64"
                        data-src="https://cdn.shocho.co/sc-site/logo-badges/us-senate-small-business-of-the-week-square-64x.png"
                        src="https://cdn.shocho.co/sc-site/logo-badges/us-senate-small-business-of-the-week-square-64x.png"
                    />
                </Link>
            )}
        </div>
    );
};

const BackToTopIcon = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.brand.lightesttan,
    color: theme.palette.brand.emberorange,
    padding: '1.25em 0',
    width: '100%',
    '&::before': {
        display: 'block',
        content: '"Back to Top"',
        whiteSpace: 'nowrap',
        transition: 'opacity 0.218s ease-in-out',
        fontFamily: PRIMARY_FONT_FAMILY,
        fontWeight: 800,
    },
    '&:hover': {
        color: theme.palette.brand.darkorange,
        backgroundColor: theme.palette.brand.lightestorange,
    },

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.background.paper,
        right: theme.spacing(3),
        bottom: theme.spacing(3),
        border: '.375rem solid currentColor',
        borderRadius: '50%',
        color: theme.palette.brand.emberorange,
        padding: '.25rem',
        position: 'absolute',
        width: '3rem',
        height: '3rem',
        '&::before': {
            color: theme.palette.text.primary,
            opacity: 0,
            paddingRight: '.5em',
            position: 'absolute',
            top: '.5em',
            right: '3rem',
        },
        '&:hover::before, &:focus::before, &:active::before': {
            opacity: 1,
        },
    },
}));

const ScrollTopButton = styled(Button)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.brand.lightesttan,
    color: theme.palette.brand.emberorange,
    '&:hover': {
        backgroundColor: theme.palette.brand.lightestorange,
        color: theme.palette.brand.darkorange,
    },
}));

export const ScrollTop: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {isMobile ? (
                <ScrollTopButton
                    variant="outlined"
                    color="secondary"
                    aria-label="Back to top"
                    onClick={handleScrollTop}
                >
                    Back to Top
                </ScrollTopButton>
            ) : isTablet ? (
                <BackToTopIcon
                    className="btn btn-backtotop footer-button transit"
                    data-scroll="top"
                    aria-label="Back to top"
                    onClick={handleScrollTop}
                >
                    <span>
                        <svg
                            className="sc-icon sc-icon--transfer-up hide-sml"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <path
                                fill="currentColor"
                                d="M8,21V19H16V21H8M8,17V15H16V17H8M8,13V11H16V13H8M19,9H5L12,2L19,9Z"
                            ></path>
                        </svg>
                    </span>
                </BackToTopIcon>
            ) : (
                <BackToTopIcon
                    className="btn btn-backtotop footer-button transit"
                    data-scroll="top"
                    aria-label="Back to top"
                    onClick={handleScrollTop}
                >
                    <span>
                        <svg
                            className="sc-icon sc-icon--transfer-up hide-sml"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <path
                                fill="currentColor"
                                d="M8,21V19H16V21H8M8,17V15H16V17H8M8,13V11H16V13H8M19,9H5L12,2L19,9Z"
                            ></path>
                        </svg>
                    </span>
                </BackToTopIcon>
            )}
        </>
    );
};
export const ChildDepartmentsLoading = (props: {
    numPerRowDesktop?: number;
}) => {
    const { numPerRowDesktop = 3 } = props;
    const gridTemplateCol = { xs: '1fr 1fr', md: '1fr 1fr 1fr' };
    const height = { xs: '50vw', md: '20vw' };
    if (numPerRowDesktop > 3) {
        gridTemplateCol.md += ' 1fr';
        height.md = '16.66vw';
    }
    return (
        <Box display="grid" gap={3} gridTemplateColumns={gridTemplateCol}>
            {[...Array(8)].map((v: number, i: number) => {
                return (
                    <React.Fragment key={'dept-loading-' + i}>
                        <Box textAlign="center">
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                sx={{ height: height, display: 'inline-block' }}
                            />
                            <Skeleton
                                variant="text"
                                width="50%"
                                sx={{ display: 'inline-block' }}
                            />
                        </Box>
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export const ChildDepartments = (props: { departments: Department[] }) => {
    const { departments } = props;
    const gridTemplateCol = { xs: '1fr 1fr', md: '1fr 1fr 1fr' };
    if (departments.length > 0 && departments[0].imageType !== 'lifestyle') {
        gridTemplateCol.md += ' 1fr';
    }
    if (departments.length === 0) {
        return <></>;
    }
    return (
        <Box display="grid" gridTemplateColumns={gridTemplateCol}>
            {departments.map((dept: Department, i: number) => {
                return (
                    <React.Fragment key={i}>
                        <ChildDepartment department={dept} />
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export const ChildDepartment = (props: { department: Department }) => {
    const { department } = props;
    return (
        <BBQGuysImageCard
            url={department.url}
            image={
                department.imageUrl || getEnvironmentVariable('NO_IMAGE_URL')
            }
            title={department.title}
            LinkComponent={ChildDepartmentLinkLevel1}
            withBorder={false}
            width={370}
            height={370}
            cardContentProps={{ m: 0, p: '4px' }}
            cardProps={{
                p: 0,
            }}
            imageProps={{
                sx: {
                    width: '100%',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    [theme.breakpoints.up('md')]: {
                        width: '100%',
                        height: '100%',
                    },
                    margin: '0 auto',
                },
            }}
        />
    );
};

export const ChildDepartmentLinksLoading = () => {
    return (
        <>
            <MobileDevice>
                <ScContainer variant="container-outside">
                    {Array(5)
                        .fill(0)
                        .map((v: number, i: number) => {
                            return (
                                <Accordion
                                    key={'dept-child-links-' + i}
                                    expanded={false}
                                    className={i === 4 ? 'last-elem' : ''}
                                >
                                    <AccordionSummary>
                                        <Skeleton variant="text" width="80%" />
                                    </AccordionSummary>
                                    <AccordionDetails></AccordionDetails>
                                </Accordion>
                            );
                        })}
                </ScContainer>
            </MobileDevice>
            <DesktopAndTabletDevice>
                <SidebarList>
                    {Array(5)
                        .fill(0)
                        .map((v: number, i: number) => {
                            return (
                                <SidebarListItem key={'dept-child-links-' + i}>
                                    <Skeleton variant="text" width="60%" />
                                    <SidebarList>
                                        {Array(5)
                                            .fill(0)
                                            .map((v2: number, i2: number) => {
                                                return (
                                                    <SidebarListItem
                                                        key={
                                                            'dept-child-links2-' +
                                                            i2
                                                        }
                                                    >
                                                        <Skeleton
                                                            variant="text"
                                                            width="90%"
                                                        />
                                                    </SidebarListItem>
                                                );
                                            })}
                                    </SidebarList>
                                </SidebarListItem>
                            );
                        })}
                </SidebarList>
            </DesktopAndTabletDevice>
        </>
    );
};
export const ChildDepartmentLinks = (props: {
    departments: Department[];
    optionalListItem?: React.ReactElement;
}) => {
    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    return (
        <>
            <MobileDevice>
                <ScContainer variant="container-outside">
                    {props.departments.map((dept: Department, i: number) => {
                        return (
                            <React.Fragment key={dept.id + '-' + i}>
                                {dept.childDepartments === null ||
                                dept.childDepartments?.length === 0 ? (
                                    <Accordion
                                        expanded={false}
                                        className={
                                            !props.optionalListItem ||
                                            props.departments.length === i + 1
                                                ? 'last-elem'
                                                : ''
                                        }
                                    >
                                        <AccordionSummary
                                            id={dept.id + '-header'}
                                        >
                                            <Link
                                                href={dept.url}
                                                variant="bold"
                                            >
                                                {dept.title}
                                            </Link>
                                        </AccordionSummary>
                                        <AccordionDetails></AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <Accordion
                                        expanded={expanded === dept.id}
                                        onChange={handleChange(dept.id)}
                                        className={
                                            props.departments.length === i + 1
                                                ? 'last-elem'
                                                : ''
                                        }
                                    >
                                        <AccordionSummary
                                            aria-controls={dept.id + '-content'}
                                            id={dept.id + '-header'}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography variant="bold">
                                                {dept.title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {dept.childDepartments ? (
                                                <List>
                                                    {dept.childDepartments.map(
                                                        (
                                                            dept2: Maybe<Department>,
                                                            n: number,
                                                        ) => {
                                                            return dept2 ? (
                                                                <ListItem
                                                                    key={n}
                                                                >
                                                                    <ChildDepartmentLinkLevel2
                                                                        key={n}
                                                                        href={
                                                                            dept2.url
                                                                        }
                                                                        display="block"
                                                                        sx={{
                                                                            pb: 1,
                                                                            pl: 1,
                                                                        }}
                                                                    >
                                                                        {
                                                                            dept2.title
                                                                        }
                                                                    </ChildDepartmentLinkLevel2>
                                                                </ListItem>
                                                            ) : null;
                                                        },
                                                    )}
                                                    <ListItem>
                                                        <Link
                                                            href={dept.url}
                                                            variant="bold"
                                                            sx={{
                                                                pb: 1,
                                                                pl: 1,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                textDecoration:
                                                                    'none',
                                                                fontWeight:
                                                                    'bold !important',
                                                                '&:hover svg': {
                                                                    fill: 'var(--brand-primary-color-dark)',
                                                                },
                                                                svg: {
                                                                    alignSelf:
                                                                        'center',
                                                                    width: '27px !important',
                                                                    height: '27px !important',
                                                                },
                                                            }}
                                                        >
                                                            Shop All{' '}
                                                            {dept.title}{' '}
                                                            <KeyboardArrowRightIcon />
                                                        </Link>
                                                    </ListItem>
                                                </List>
                                            ) : null}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {props.optionalListItem && (
                        <React.Fragment>
                            <Accordion expanded={false} className="last-elem">
                                <AccordionSummary id={'additional-list-header'}>
                                    {props.optionalListItem}
                                </AccordionSummary>
                                <AccordionDetails></AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    )}
                </ScContainer>
            </MobileDevice>
            <DesktopAndTabletDevice>
                <SidebarList>
                    {props.departments.map((dept: Department, i: number) => {
                        return (
                            <SidebarListItem key={i}>
                                <ChildDepartmentLinkLevel1 href={dept.url}>
                                    {dept.title}
                                </ChildDepartmentLinkLevel1>
                                {dept.childDepartments ? (
                                    <SidebarList>
                                        {dept.childDepartments.map(
                                            (
                                                dept2: Maybe<Department>,
                                                n: number,
                                            ) => {
                                                return dept2 ? (
                                                    <SidebarListItem key={n}>
                                                        <ChildDepartmentLinkLevel2
                                                            key={n}
                                                            href={dept2.url}
                                                        >
                                                            {dept2.title}
                                                        </ChildDepartmentLinkLevel2>
                                                    </SidebarListItem>
                                                ) : null;
                                            },
                                        )}
                                    </SidebarList>
                                ) : null}
                            </SidebarListItem>
                        );
                    })}
                    {props.optionalListItem && (
                        <SidebarListItem>
                            {props.optionalListItem}
                        </SidebarListItem>
                    )}
                </SidebarList>
            </DesktopAndTabletDevice>
        </>
    );
};

const LearningCenterCardImage = ({ content }: { content: ContentLinks }) => {
    return (
        <Link href={content.url}>
            <CardMedia
                component="img"
                image={
                    content.imageUrl || getEnvironmentVariable('NO_IMAGE_URL')
                }
                alt={content.title}
                sx={{ maxHeight: 215, width: { xs: '25vw', md: '100%' } }}
            />
        </Link>
    );
};
const LearningCenterCardInfo = ({ content }: { content: ContentLinks }) => {
    return (
        <Box display="flex" flexDirection="column" alignSelf="center">
            <Link href={content.url}>
                <Typography variant="bold">{content.title}</Typography>
            </Link>
            <DesktopAndTabletDevice>
                {content.description ? (
                    <Typography variant="body1">
                        {content.description}
                    </Typography>
                ) : null}
            </DesktopAndTabletDevice>
        </Box>
    );
};

export const LearningCenterLinksLoading = (props: { count?: number }) => {
    const { count = 6 } = props;

    return (
        <Grid container spacing={3}>
            {[...Array(count).fill(0)].map((n: number, i: number) => {
                return (
                    <Grid key={'learning-center-' + i} item xs={12} md={4}>
                        <DesktopAndTabletDevice>
                            <Card
                                sx={{
                                    border: 0,
                                    display: 'flex',
                                    flexDirection: { xs: 'row', md: 'column' },
                                }}
                            >
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        height: 215,
                                        width: { xs: '25vw', md: '100%' },
                                    }}
                                />
                                <CardContent sx={{ pt: 2, pl: 0 }}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </CardContent>
                            </Card>
                        </DesktopAndTabletDevice>
                        <MobileDevice>
                            <Stack direction="row" gap={2}>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        maxHeight: 215,
                                        width: { xs: '25vw', md: '100%' },
                                    }}
                                />
                                <Skeleton variant="text" />
                            </Stack>
                        </MobileDevice>
                    </Grid>
                );
            })}
        </Grid>
    );
};
export const LearningCenterLinks = (props: { content: ContentLinks[] }) => {
    return (
        <Grid container spacing={3}>
            {props.content.map((content: ContentLinks, i: number) => {
                return (
                    <Grid
                        key={'learning-center-' + content.url}
                        item
                        xs={12}
                        md={4}
                    >
                        <DesktopAndTabletDevice>
                            <BBQGuysImageCard
                                url={content.url}
                                image={content.imageUrl}
                                title={content.title}
                                titleAlign="left"
                                withTitle={true}
                                withBorder={false}
                                height={200}
                            >
                                {content.description}
                            </BBQGuysImageCard>
                        </DesktopAndTabletDevice>
                        <MobileDevice>
                            <Stack direction="row" gap={2}>
                                <LearningCenterCardImage content={content} />
                                <LearningCenterCardInfo content={content} />
                            </Stack>
                        </MobileDevice>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const PhoneLink = (props: {
    inline?: boolean;
    number?: string | null;
    showIcon?: boolean;
    icon?: React.ReactElement;
    linkProps?: any;
    statusMessage?: string;
}) => {
    const { getProAccountRepPhoneNumber } = React.useContext(UserAuth); // falls back to site phone if no rep or not logged in
    const {
        showIcon = true,
        icon = null,
        inline = false,
        statusMessage = '',
    } = props;
    let { number = getProAccountRepPhoneNumber() } = props;
    if (number === null) {
        number = getProAccountRepPhoneNumber();
    }
    return (
        <span>
            <Link href={'tel:' + number} {...props.linkProps}>
                {showIcon &&
                    !inline &&
                    (icon ? (
                        icon
                    ) : (
                        <PhoneOutlined sx={{ mr: 1, opacity: '0.6' }} />
                    ))}
                <span>{number}</span>
            </Link>
            {statusMessage && (
                <Typography
                    sx={{
                        fontStyle: 'italic',
                        display: 'block',
                        color: '#2d8442',
                        marginTop: 0.5,
                    }}
                >
                    {statusMessage}
                </Typography>
            )}
        </span>
    );
};

export const EmailLink = (props: {
    emailAddress?: string | null;
    showIcon?: boolean;
    emailText?: string;
    icon?: React.ReactElement;
    linkProps?: any;
}) => {
    const { email } = React.useContext(SiteContext);
    const { showIcon = true, emailText = 'Email Us', icon = null } = props;
    let { emailAddress = email } = props;
    if (emailAddress === null) {
        emailAddress = email;
    }
    return (
        <Link
            href={
                (emailAddress.indexOf('@') > -1 ? 'mailto:' : '') + emailAddress
            }
            {...props.linkProps}
        >
            {showIcon &&
                (icon ? (
                    icon
                ) : (
                    <EmailOutlined sx={{ mr: 1, opacity: '0.6' }} />
                ))}
            <span>{emailText}</span>
        </Link>
    );
};

export const LiveChatLink = (props: {
    showIcon?: boolean;
    icon?: React.ReactElement;
    linkProps?: any;
    title?: string;
    chatMessage?: string;
}) => {
    const {
        showIcon = true,
        icon = null,
        title = 'Live Chat',
        chatMessage = '',
        linkProps = {},
    } = props;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        openZendeskChat();
    };
    const sx = linkProps?.sx ?? {};
    linkProps.sx = {
        justifyContent: 'left',
        alignItems: 'center',
        display: 'inline-flex',
        ...sx,
    };
    return (
        <div style={{ display: 'inline-block' }}>
            <Link
                className="btn-chat"
                href="#"
                rel="nofollow"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    handleClick(e)
                }
                {...linkProps}
            >
                {showIcon &&
                    (icon ? (
                        icon
                    ) : (
                        <ChatBubbleOutline sx={{ mr: 1, opacity: '0.6' }} />
                    ))}
                <span>{title}</span>
            </Link>
            {chatMessage && (
                <Typography
                    style={{
                        fontStyle: 'italic',
                        display: 'block',
                        color: '#63676D',
                    }}
                >
                    {chatMessage}
                </Typography>
            )}
        </div>
    );
};

export const MediaRow = (props: {
    image: any;
    children: any;
    sx?: SxProps;
    spacing?: number;
}) => {
    return (
        <Stack
            direction="row"
            spacing={props.spacing}
            sx={{ width: '100%', position: 'relative', ...props.sx }}
        >
            <Box sx={{ mr: 2 }}>{props.image}</Box>
            <Box sx={{ width: '100%' }}>{props.children}</Box>
        </Stack>
    );
};

export const PanelBox = (props: {
    title?: string;
    header?: any;
    sx?: SxProps;
    children: any;
}) => {
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[50],
                border: `1px solid ${theme.palette.divider}`,
                p: 2.5,
                boxShadow: '0 0.0625em 0.1875em rgb(0, 0, 0, .15)',
                ...props.sx,
            }}
        >
            {props.children}
        </Box>
    );
};

export const Panel = (props: {
    title?: string;
    header?: any;
    sx?: SxProps;
    sxContents?: SxProps;
    children: any;
}) => {
    return (
        <Box
            sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                overflow: 'visible',
                ...props.sx,
            }}
        >
            <Box
                className="panel-header"
                sx={{
                    backgroundColor: theme.palette.grey[200],
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    p: 2,
                    overflow: 'hidden',
                }}
            >
                {props.title ? (
                    <Typography variant="bold">{props.title}</Typography>
                ) : (
                    props.header
                )}
            </Box>
            <Box sx={props.sxContents}>{props.children}</Box>
        </Box>
    );
};

const ExpertLinks = () => {
    const linkProps = {
        sx: {
            '& svg': {
                width: '24px',
                height: '24px',
                verticalAlign: '-.4375em',
            },
        },
    };

    return (
        <>
            <PhoneLink linkProps={linkProps} />
            <LiveChatLink linkProps={linkProps} />
            <EmailLink
                linkProps={linkProps}
                emailText="Email an Expert"
                emailAddress="/contact-us"
            />
        </>
    );
};

export const AskAnExpert = () => {
    const { getProAccountRepPhoto } = React.useContext(UserAuth);
    return (
        <Panel
            header={
                <>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 2,
                            gridTemplateColumns: {
                                xs: '1fr 2fr',
                                lg: '75px 1fr',
                            },
                        }}
                    >
                        <Image
                            src={getProAccountRepPhoto()}
                            alt="photo of an expert"
                            width={250}
                            height={250}
                            sx={{
                                display: { lg: 'inherit' },
                                width: '100%',
                                height: 'auto',
                                transform: 'scale(1.5)',
                                alignSelf: 'end',
                            }}
                        />
                        <Box sx={{ paddingLeft: '1rem' }}>
                            <Typography
                                component="h2"
                                variant="sectionTitle"
                                fontSize={18}
                                sx={{ p: 0 }}
                            >
                                Ask an Expert
                            </Typography>
                            <DesktopAndTabletDevice>
                                <Typography variant="body2">
                                    Buy with confidence. Contact our experts
                                    today.
                                </Typography>
                            </DesktopAndTabletDevice>
                            <MobileDevice>
                                <Typography variant="body2">
                                    Buy with confidence. <br />
                                    Contact our experts today.
                                </Typography>
                            </MobileDevice>
                        </Box>
                    </Box>
                </>
            }
            sxContents={{ p: 2 }}
        >
            <Stack direction="column" spacing={2}>
                <ExpertLinks />
            </Stack>
        </Panel>
    );
};

export const SignOutButton = (props: {
    title?: string;
    onComplete?: () => void;
}) => {
    const {
        title = 'Sign Out',
        onComplete = () => {
            return;
        },
    } = props;
    const { logout } = React.useContext(UserAuth);

    return (
        <Button
            className="btn-signout"
            variant="link"
            onClick={() =>
                logout().then(() => {
                    onComplete();
                })
            }
        >
            {title}
        </Button>
    );
};

export const SignInButton = (props: {
    type: 'login' | 'register';
    button: any;
    onComplete?: () => void;
}) => {
    const {
        type = 'login',
        onComplete = () => {
            return;
        },
    } = props;
    let { button } = props;
    const [view, setView] = React.useState(type);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    button = React.cloneElement(button, { onClick: handleClickOpen() });

    const formProps = {
        style: {
            padding: theme.spacing(4),
            // display: 'flex',
            // justifyContent: 'center'
        },
    };
    return (
        <>
            {button}
            <Modal
                open={open}
                handleClose={handleClose}
                maxWidth="sm"
                title={
                    view === 'login' ? 'Account Sign In' : 'Create an Account'
                }
            >
                <DialogContent>
                    {view === 'login' ? (
                        <>
                            <LoginForm
                                inline
                                onComplete={() => {
                                    handleClose();
                                    onComplete();
                                }}
                                formProps={formProps}
                            />
                            <span>Don&apos;t have an account? </span>
                            <Button
                                variant="link"
                                onClick={() => setView('register')}
                                sx={{ mb: 1 }}
                            >
                                Sign up
                            </Button>
                        </>
                    ) : (
                        <>
                            <RegistrationForm
                                inline
                                onComplete={() => {
                                    handleClose();
                                    onComplete();
                                }}
                                formProps={formProps}
                            />
                            <span>Already have an account? </span>
                            <Button
                                variant="link"
                                onClick={() => setView('login')}
                                sx={{ mb: 1 }}
                            >
                                Sign in
                            </Button>
                        </>
                    )}
                </DialogContent>
            </Modal>
        </>
    );
};

export const FullPageIconBanner = (props: {
    icon: any;
    title: string;
    subtitle?: string;
}) => {
    const icon = React.cloneElement(props.icon, { sx: { fontSize: 56, m: 0 } });
    const { recentlyViewedItems } = React.useContext(UserAuth);
    const { pathname } = useLocation();
    return (
        <Paper
            sx={{
                my: 4,
                background: theme.palette.grey[100],
                pt: { xs: 8, md: 12 },
                px: 5,
                width: '100%',
                mx: 'auto',
                height: 400,
                textAlign: 'center',
            }}
        >
            <Stack spacing={3} alignItems={'center'}>
                <Box
                    sx={{
                        display: 'flex',
                        border: `3px dotted ${theme.palette.divider}`,
                        borderRadius: '50%',
                        color: theme.palette.brand.lightesttan,
                        p: 2,
                        height: 56,
                        width: 56,
                        my: 0,
                        mx: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxSizing: 'content-box',
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            display: 'flex',
                            color: theme.palette.brand.charcoalgray,
                        }}
                    >
                        {icon}
                    </Box>
                </Box>
                <Typography variant="title" component="h1">
                    {props.title}
                </Typography>
                <Typography variant="italic">{props.subtitle}</Typography>
                {pathname === '/cart' && (
                    <Box display="flex" justifyContent="center">
                        <Button
                            href={recentlyViewedItems[0]?.url ?? '/'}
                            variant="outlined"
                        >
                            Continue Shopping
                        </Button>
                    </Box>
                )}
            </Stack>
        </Paper>
    );
};

export const Address = (props: { contactInfo: Contact }) => {
    const { contactInfo } = props;
    return (
        <address>
            {contactInfo?.address1}
            <br />
            {contactInfo?.address2 ? (
                <>
                    {contactInfo.address2}
                    <br />
                </>
            ) : null}
            {contactInfo?.city ? <>{contactInfo.city},&nbsp;</> : null}
            {contactInfo?.state + ' ' + contactInfo?.zip}
            <br />
        </address>
    );
};

export const PageHeader = (props: {
    title?: string;
    subtitle?: string;
    variant?: 'horizontal' | 'vertical';
    children?: any;
    subtitleStyles?: any;
    dynamicMultiline?: boolean;
    style?: React.CSSProperties;
}) => {
    const {
        title = '',
        subtitle = '',
        variant = 'vertical',
        children = null,
        subtitleStyles = {},
        dynamicMultiline = false,
        style = {},
    } = props;

    const titleRef = React.useRef<HTMLSpanElement>(null);
    const subtitleRef = React.useRef<HTMLSpanElement>(null);
    const [updatedVariant, setUpdatedVariant] = React.useState(variant);
    const hasMarginTop = style && 'marginTop' in style;
    const hasMarginBottom = style && 'marginBottom' in style;

    React.useEffect(() => {
        const handleResize = () => {
            if (dynamicMultiline) {
                if (titleRef.current && subtitleRef.current) {
                    const titleWidth = titleRef.current.offsetWidth;
                    const subtitleWidth = subtitleRef.current.offsetWidth;
                    const totalWidth = titleWidth + subtitleWidth;
                    const viewportWidth = window.innerWidth;

                    const thresholdPercentage = 0.9; // 90% of viewport width

                    if (
                        totalWidth > viewportWidth * thresholdPercentage &&
                        updatedVariant === 'horizontal'
                    ) {
                        setUpdatedVariant('vertical');
                    } else if (
                        totalWidth <= viewportWidth * thresholdPercentage &&
                        updatedVariant === 'vertical'
                    ) {
                        setUpdatedVariant('horizontal');
                    }
                }
            }
        };

        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dynamicMultiline, updatedVariant]);

    return (
        <Box
            display="flex"
            flexDirection={updatedVariant === 'horizontal' ? 'row' : 'column'}
            alignItems={updatedVariant === 'horizontal' ? 'center' : 'start'}
            gap={1}
            marginTop={
                hasMarginTop
                    ? undefined
                    : updatedVariant === 'horizontal'
                      ? 0
                      : 3
            }
            marginBottom={hasMarginBottom ? undefined : 2}
            whiteSpace="break-spaces"
            style={style}
        >
            <Typography variant="h1" ref={titleRef}>
                {title === '' && children !== null ? children : title}
            </Typography>
            {subtitle !== '' && (
                <Typography
                    variant="muted"
                    ref={subtitleRef}
                    sx={{
                        fontSize: '1rem',
                        whiteSpace: 'nowrap',
                        ...(subtitleStyles as React.CSSProperties),
                    }}
                >
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};

export const ProductGridPageTitle = (props: {
    title: string | React.ReactElement;
    subtitle?: string;
    pageTitleId?: string;
}) => {
    const { title, subtitle, pageTitleId = 'titleHeader' } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    return (
        <Box>
            <Box component="span">
                <Typography
                    id={pageTitleId}
                    component="h1"
                    variant="title"
                    sx={{
                        padding:
                            searchParams?.size === 0
                                ? '24px 0 24px 0'
                                : '0 0 24px 0',
                        whiteSpace: 'break-spaces',
                    }}
                >
                    <>
                        {title}{' '}
                        {subtitle && (
                            <small
                                style={{
                                    color: '#636d76',
                                    fontSize: '.75em',
                                    letterSpacing: '-.01875em',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {subtitle}
                            </small>
                        )}
                    </>
                </Typography>
            </Box>
        </Box>
    );
};

export const FooterButtonLink = (props: {
    className: string;
    children: React.ReactNode;
}) => {
    return (
        <Button
            variant="link"
            className={props.className}
            sx={{
                margin: isMobile ? '0 !important' : 'inherit',
                fontFamily: theme => theme.typography.body1.fontFamily,
                fontSize: theme => theme.typography.body1.fontSize,
                fontWeight: theme => theme.typography.fontWeightRegular,
            }}
        >
            {props.children}
        </Button>
    );
};

export const StatusLabel = (props: { status: string; fullWidth?: boolean }) => {
    const { status, fullWidth = false } = props;

    const getOrderStatusColor = (status: string) => {
        switch (status) {
            case 'Shipped':
                return 'success';
            case 'Cancelled':
                return 'error';
            case 'Pending Shipment':
                return 'info';
            case 'unpaid':
                return 'secondary';
            default:
                return 'warning';
        }
    };

    return (
        <Chip
            label={status}
            color={getOrderStatusColor(status)}
            variant="filled"
            size="small"
            sx={{ width: fullWidth ? '100%' : 'auto', borderRadius: '4px' }}
        />
    );
};

export const QuestionsAboutYourOrderBox = ({
    orderNumber,
    withCustomerSupportPhoto = false,
    sx = {},
}: {
    orderNumber: string;
    withCustomerSupportPhoto?: boolean;
    sx?: SxProps;
}) => {
    const { phone, site } = React.useContext(SiteContext);
    const { getProAccountRepPhoto } = React.useContext(UserAuth);
    return (
        <PanelBox
            sx={{
                mb: 3,
                display: 'grid',
                gap: 1,
                gridTemplateColumns: '1fr',
                maxWidth: 600,
                mx: 'auto',
                ...sx,
            }}
        >
            {withCustomerSupportPhoto && (
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none' } }}>
                    <Avatar
                        sx={{ width: 150, height: 150 }}
                        alt={'Customer Support Rep'}
                        src={getProAccountRepPhoto()}
                    />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="div" textAlign="center" variant="h4">
                    Questions About Your Order?
                </Typography>
                <Typography component="div" textAlign="center">
                    Call us at {phone} or <LiveChatLink showIcon={false} />
                </Typography>
                <Box
                    textAlign="center"
                    dangerouslySetInnerHTML={{
                        __html: site.locations?.website?.hours?.secondary ?? '',
                    }}
                />
                <Typography
                    component="div"
                    textAlign="center"
                    variant="h5"
                    sx={{ mt: 1 }}
                >
                    Order Number:&nbsp;
                    <Typography variant="bold" color="primary">
                        {orderNumber}
                    </Typography>
                </Typography>
            </Box>
        </PanelBox>
    );
};

export function FuelTypeIcon({
    item,
    svgProps = {},
}: {
    item: Item;
    svgProps?: SvgIconProps;
}) {
    return (
        <>
            {item.fuelType === 'Charcoal' && <OutdoorGrillIcon {...svgProps} />}
            {item.fuelType === 'Natural Gas' && (
                <FuelTypeNaturalGas {...svgProps} />
            )}
            {item.fuelType === 'Propane' && <FuelTypePropane {...svgProps} />}
        </>
    );
}

export const HandyInstallation = ({ item }: { item: Item }) => {
    const CONTENT_ID = 39839;
    const CONTENT_TITLE = 'Leave Assembly to the Pros';
    const PRICE = item.pricingFormatted.installation.substring(
        0,
        item.pricingFormatted.installation.indexOf('.'),
    );
    return (
        <Stack
            sx={{
                my: 4,
                columnGap: 4,
                rowGap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: { xs: 'center', md: 'left' },
                alignItems: 'center',
            }}
            id="handy"
        >
            <ContentModalButton
                contentId={CONTENT_ID}
                title={CONTENT_TITLE}
                button={
                    <Image
                        src="https://cdn.shocho.co/sc-site/contentPages/39247-HandyAssembly/handy_logo.svg"
                        width={100}
                        height={75}
                        alt="Handy Logo"
                    />
                }
            />
            <Box>
                <Typography variant="bold">
                    Professional Grill Assembly for {PRICE}
                </Typography>
                <Typography variant="body1" component="p">
                    Book a professional to help assemble this grill for only{' '}
                    {PRICE}. All bookings and inquiries are handled by Handy.
                    &nbsp;
                    <ContentModalButton
                        contentId={CONTENT_ID}
                        title={CONTENT_TITLE}
                        button={<Button variant="link">Learn More</Button>}
                    />
                </Typography>
            </Box>
        </Stack>
    );
};

export const PoliciesPopupLink = ({
    title = 'Policies',
    children,
}: {
    title?: string;
    children: React.ReactChildren | string;
}) => {
    return (
        <ContentModalButton
            contentId={35490}
            title={title}
            button={<Link>{children}</Link>}
        />
    );
};
export const FitmentCalculators = ({ item }: { item: Item }) => {
    if (item.category.toLowerCase().indexOf('fire glass') > -1) {
        return <FireGlassCalculatorModal />;
    }
    return <></>;
};
export const FireGlassCalculatorModal = () => {
    const CONTENT_ID = 42381;
    const CONTENT_TITLE = ''; //'Fire Glass Calculator';
    return (
        <Stack
            sx={{
                my: 4,
                columnGap: 2,
                rowGap: 2,
                flexDirection: 'row',
                textAlign: 'left',
                alignItems: 'center',
            }}
            id="fire-glass-calc"
        >
            <ContentModalButton
                contentId={CONTENT_ID}
                title={CONTENT_TITLE}
                size="sm"
                dialogContentProps={{ sx: { p: 0 } }}
                button={<CalculateTwoTone fontSize="large" color="info" />}
            />
            <Box>
                <Typography variant="bold">Fire Glass Calculator</Typography>
                <Typography variant="body1" component="p">
                    How much fire glass do you need? &nbsp;
                    <ContentModalButton
                        contentId={CONTENT_ID}
                        title={CONTENT_TITLE}
                        size="sm"
                        dialogContentProps={{ sx: { p: 0 } }}
                        button={<Button variant="link">Learn More</Button>}
                    />
                </Typography>
            </Box>
        </Stack>
    );
};
