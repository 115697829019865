import { FavoriteOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import AddToCart from '../../../../Components/Buttons/AddToCart/AddToCart';
import ImageCarousel from '../../../../Components/ImageCarousel/ImageCarousel';
import QtyInput from '../../../../Components/QtyInput/QtyInput';
import { WishlistButton } from '../../../../Components/Wishlists/Wishlists';
import {
    // FitmentCalculators,
    HandyInstallation,
} from '../../../../Components/_base/BBQGuysComponents';
import { AddToCartItem } from '../../../../Contexts/CartProvider';
import {
    ProductQtyContext,
    ProductQtyProvider,
} from '../../../../Contexts/ProductQtyProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { ImageType, Item } from '../../../../types';
import { ItemMeta } from './ItemMeta';
import ItemOptionsDropdown from './ItemOptionsDropdown';
import ItemPriceAndQty from './ItemPriceAndQty';
import ItemWarrantiesDropdown from './ItemWarrantiesDropdown';

const ItemAboveTheFoldOutOfStock = () => {
    const { item } = React.useContext(ProductQtyContext);

    return (
        <>
            <Stack
                spacing={1}
                id="item-primary-product-options"
                sx={{ marginBottom: '32px' }}
            >
                <div>
                    <Typography variant="outOfStock">{'Sold Out'}</Typography>
                    <Box>
                        <Typography variant="body1">
                            Sorry, this product is no longer available
                        </Typography>
                    </Box>
                </div>
                {item.optionsAvailableCount > 0 && (
                    <div>
                        <ItemOptionsDropdown itemId={item.id} />
                    </div>
                )}
            </Stack>
        </>
    );
};

const ItemAboveTheFoldLowerSection = () => {
    const { item, qty, loading, disableAddToCartButton, getAddToCartItems } =
        React.useContext(ProductQtyContext);

    const [items, setItems] = React.useState([
        { item: item, qty: 1 },
    ] as AddToCartItem[]);

    React.useEffect(() => {
        setItems(getAddToCartItems());
    }, []);

    if (!item.isAvailable) {
        return <ItemAboveTheFoldOutOfStock />;
    }

    return (
        <>
            <ItemPriceAndQty item={item} />
            <Stack
                spacing={1}
                id="item-primary-product-options"
                sx={{ marginBottom: '32px' }}
            >
                {item.optionsAvailableCount > 0 && (
                    <div>
                        <ItemOptionsDropdown itemId={item.id} />
                    </div>
                )}
                {item.hasExtendedWarranty && (
                    <div>
                        <ItemWarrantiesDropdown
                            itemId={item.id}
                            onReset={() => {
                                setItems(getAddToCartItems());
                            }}
                            onSelectChange={itm => {
                                setItems(getAddToCartItems());
                            }}
                        />
                    </div>
                )}
            </Stack>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    gap: 2,
                }}
            >
                <QtyInput />
                <AddToCart
                    items={items}
                    qty={qty}
                    disabled={loading || disableAddToCartButton}
                    size="large"
                    isPrimaryButton={true}
                />
            </Box>

            <WishlistButton
                button={
                    <IconButton
                        sx={{
                            fontFamily: theme => theme.typography.fontFamily,
                            fontSize: theme => theme.typography.body1.fontSize,
                            marginTop: '32px',
                            marginBottom: '32px',
                            color: theme => theme.palette.secondary.main,
                        }}
                        className="btn-savetowishlist"
                    >
                        <FavoriteOutlined
                            sx={{
                                fontSize: theme =>
                                    theme.typography.body1.fontSize,
                                mr: 1,
                            }}
                        />{' '}
                        Save to List
                    </IconButton>
                }
                items={[{ id: item.id, qty: qty, notes: '' }]}
            />
            {/* <FitmentCalculators item={item} /> this need to be designed out, to include all 3 calculators listed */}
            {item.hasInstallationAvailable && <HandyInstallation item={item} />}
        </>
    );
};

export const ItemAboveTheFoldDisplay = (props: {
    itemImages?: ImageType[];
}) => {
    const { itemImages = [] } = props;
    const { item } = React.useContext(ProductQtyContext);
    return (
        <>
            <MobileDevice>
                <ItemMeta item={item} />
                <ImageCarousel item={item} itemImages={itemImages} />
                <ItemAboveTheFoldLowerSection />
            </MobileDevice>
            <DesktopAndTabletDevice>
                <Grid container columnSpacing={3}>
                    <Grid item md={7}>
                        <ImageCarousel item={item} itemImages={itemImages} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <ItemMeta item={item} />
                        <ItemAboveTheFoldLowerSection />
                    </Grid>
                </Grid>
            </DesktopAndTabletDevice>
        </>
    );
};
export const ItemAboveTheFold = (props: {
    item: Item;
    itemImages?: ImageType[];
}) => {
    const { item, itemImages = [] } = props;
    return (
        <ProductQtyProvider item={item}>
            <ItemAboveTheFoldDisplay itemImages={itemImages} />
        </ProductQtyProvider>
    );
};

export default ItemAboveTheFold;
