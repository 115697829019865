import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { client } from '../Graphql/ApolloClient';
import {
    BASIC_USER_FIELDS,
    CONTACT_FIELDS,
    ITEM_FIELDS,
    PAGINATED_ITEMS,
} from '../Graphql/fragments';
import { getEnvironmentVariable } from '../config';
import {
    deleteCookie,
    empty,
    getCookie,
    getCookieDomain,
    getOldSiteDomain,
    setDualCookie,
} from '../functions';
import {
    Item,
    Maybe,
    Order,
    User,
    UserAddressesResponse,
    UserAuth as UserAuthResponse,
} from '../types';
import { CartContext } from './CartProvider';
import { SiteContext } from './SiteProvider';
import {
    getPointType,
    removePaymentDeferredType,
} from './PaymentDeferredUtils';

export const QL_USER = gql`
    ${BASIC_USER_FIELDS}
    query getUser {
        me {
            ...BasicUserFields
        }
    }
`;

export const QL_USER_ADDRESSES = gql`
    ${CONTACT_FIELDS}
    query getUserAddresses {
        userAddresses {
            billing {
                ...ContactFields
            }
            shipping {
                ...ContactFields
            }
            user {
                ...ContactFields
            }
        }
    }
`;

export const QL_USER_REAUTH = gql`
    ${BASIC_USER_FIELDS}
    mutation reauthUser($token: String!, $siteId: ID) {
        reauthorize(token: $token, siteId: $siteId) {
            errorMessage
            authenticated
            token
            user {
                ...BasicUserFields
            }
        }
    }
`;

export const QL_USER_LOGOUT = gql`
    mutation logoutUser($jwt: String!) {
        logout(jwt: $jwt) {
            success
        }
    }
`;

export const QL_USER_LOGIN = gql`
    ${BASIC_USER_FIELDS}
    mutation loginUser($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            errorMessage
            authenticated
            token
            user {
                ...BasicUserFields
            }
        }
    }
`;

export const QL_USER_REGISTER = gql`
    ${BASIC_USER_FIELDS}
    mutation registerUser($email: String!, $password: String!) {
        register(email: $email, password: $password) {
            errorMessage
            authenticated
            token
            user {
                ...BasicUserFields
            }
        }
    }
`;

export const QL_USER_PASSWORD_RESET = gql`
    mutation resetUserPassword($email: String!) {
        resetPassword(email: $email) {
            errorMessage
            success
            expires
        }
    }
`;

export const QL_USER_PASSWORD_UPDATE = gql`
    mutation updateUserPassword(
        $email: String!
        $token: String!
        $newPassword: String!
    ) {
        updatePassword(
            email: $email
            token: $token
            newPassword: $newPassword
        ) {
            errorMessage
            success
        }
    }
`;

export const QL_RECENTLY_VIEWED = gql`
    ${PAGINATED_ITEMS}
    query recentlyViewedItems($itemIds: ID!, $pricingTierId: Int) {
        itemsByIds(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export type UserAuthContextProps = {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    isPro: boolean;
    pricingTierId: boolean;
    isEmployee: boolean;
    proAccountRep: {
        id: string;
        name: string;
        email: string;
        photo: string;
        phone: string;
    };
    company: string;
    lastLogin: string;
};
export interface LoginResponse {
    order: Order;
    token: string;
}
export interface LogoutResponse {
    order: Order;
}

const RECENTLY_VIEWED_ITEMS_MAX = 15;
const LOCAL_STORAGE_KEY_PRO = 'isPro';
const LOCAL_STORAGE_KEY_PRO_TIER_ID = 'pro_tier_id';
const LOCAL_STORAGE_KEY_RECENTLY_VIEWED = 'RecentlyViewedItems';
const LOCAL_STORAGE_KEY_USERINFO = 'user_info';
const LOCAL_STORAGE_KEY_LOGIN_REDIRECT_URL = 'loginRedirectUrl';
export const COOKIE_USER_ID = 'userid'; // used for maintaining login while on old site (can remove when old site is no longer used)
export const COOKIE_USER_ID_CART = 'userid_cart'; // used for maintaining login while on old site (can remove when old site is no longer used)
export const COOKIE_PRO_TIER_ID = 'pro_tier_id'; // used for maintaining login while on old site (can remove when old site is no longer used)
export const COOKIE_JWT = 'jwt'; // used for maintaining login while on old site (can remove when old site is no longer used)
const GUEST_INFO = {
    id: '',
    firstName: 'Guest',
    lastName: 'User',
    name: 'Guest User',
    email: '',
    isPro: false,
    pastCustomer: false,
    isEmployee: false,
    pricingTierId: 0,
    actValue: 0,
    proAccountRep: {
        id: '0',
        name: 'Customer Support Rep',
        email: 'pro@bbqguys.com',
        photo: 'https://cdn.shocho.co/sc-site/redesign/people/ask-the-experts--delva.png?i10c=img.resize(width:250,height:250)',
        phone: '1-877-743-2269',
    },
    rewardPoints: {
        number: '',
        available: 0,
        cashValue: 0.0,
    },
    birthday: new Date().getFullYear() + '-01-01',
};

export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    if (base64Url === undefined) {
        return { exp: new Date().getTime() / 1000 };
    }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

export const reauthenticateUser = (
    siteId: number,
): Promise<UserAuthResponse> => {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                const jsonToken = getJsonWebToken();
                if (empty(jsonToken)) {
                    reject({});
                    return;
                }
                const response = await client.mutate({
                    mutation: QL_USER_REAUTH,
                    variables: {
                        token: jsonToken,
                        siteId: siteId,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    throw response.errors[0];
                } else if (
                    response.data &&
                    response.data.reauthorize &&
                    response.data.reauthorize.authenticated
                ) {
                    resolve(response.data.reauthorize);
                } else if (
                    response.data &&
                    response.data.reauthorize &&
                    !empty(response.data.reauthorize.errorMessage)
                ) {
                    throw response.data.reauthorize.errorMessage;
                } else {
                    clearUserFromStorage();
                }
            } catch (err) {
                console.error(err);
            } finally {
                reject({});
            }
        })();
    });
};

export const getJsonWebToken = () => {
    return getCookie(COOKIE_JWT);
};

export const getUserProTierId = (): number => {
    const tierId =
        getCookie(COOKIE_PRO_TIER_ID) ||
        localStorage.getItem(LOCAL_STORAGE_KEY_PRO_TIER_ID) ||
        '0';
    return parseInt(tierId);
};

const clearUserFromStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_PRO);
    localStorage.removeItem(LOCAL_STORAGE_KEY_PRO_TIER_ID);
    removePaymentDeferredType(getPointType());

    // clear old site
    // used for maintaining login while on old site (can remove when old site is no longer used)
    deleteCookie(COOKIE_USER_ID, '/', getCookieDomain());
    deleteCookie(COOKIE_USER_ID_CART, '/', getCookieDomain());
    deleteCookie(COOKIE_PRO_TIER_ID, '/', getCookieDomain());
    deleteCookie(COOKIE_JWT, '/', getCookieDomain());
    // clear current site
    deleteCookie(COOKIE_USER_ID, '/');
    deleteCookie(COOKIE_USER_ID_CART, '/');
    deleteCookie(COOKIE_PRO_TIER_ID, '/');
    deleteCookie(COOKIE_JWT, '/');

    client.cache.reset();
    client.cache.gc();
};

export const UserAuth = React.createContext({
    user: GUEST_INFO as User,
    authenticated: false as boolean,
    errorMessage: '' as string,
    successMessage: '' as string,
    registerErrorMessage: '' as string,
    jsonWebToken: '' as string,
    recentlyViewedItems: [] as Item[],
    getProAccountRepPhoto: (): string => {
        return getEnvironmentVariable('NO_IMAGE_URL');
    },
    getUserId: (): string => {
        return '0';
    },
    getUser: (useCache?: boolean): Promise<User> => {
        return new Promise(() => {
            return;
        });
    },
    getUserAddresses: (): Promise<UserAddressesResponse> => {
        return new Promise(() => {
            return;
        });
    },
    setUser: (userInfo: User): void => {
        return;
    },
    isLoggedIn: (): boolean => {
        return false;
    },
    isPro: (): boolean => {
        return getUserProTierId() > 0;
    },
    getProTierId: (): number => {
        return getUserProTierId();
    },
    getPricingTierId: (): number => {
        return getUserProTierId();
    },
    isGuest: (): boolean => {
        return true;
    },
    isEmployee: (): boolean => {
        return false;
    },
    addToRecentlyViewedItem: (item: Item) => {
        return;
    },
    getRecentlyViewedItems: (): Promise<Item[]> => {
        return new Promise(() => {
            return;
        });
    },
    logout: (): Promise<LogoutResponse> => {
        return new Promise(() => {
            return;
        });
    },
    login: (email: string, password: string): Promise<LoginResponse> => {
        return new Promise(() => {
            return;
        });
    },
    reauthorize: (): Promise<string | unknown> => {
        return new Promise(() => {
            return;
        });
    },
    checkForReauth: (): void => {
        return;
    },
    register: (email: string, password: string): Promise<string> => {
        return new Promise(() => {
            return;
        });
    },
    passwordReset: (email: string): Promise<string> => {
        return new Promise(() => {
            return;
        });
    },
    updatePassword: (
        email: string,
        token: string,
        newPassword: string,
    ): Promise<string> => {
        return new Promise(() => {
            return;
        });
    },
    setRegistrationErrorMessage: (message: string) => {
        return;
    },
    setErrorMessage: (message: string) => {
        return;
    },
    setSuccessMessage: (message: string) => {
        return;
    },
    setLoginRedirectUrl: () => {
        return;
    },
    getLoginRedirectUrl: (): string => {
        return '';
    },
    getProAccountRepPhoneNumber: () => {
        return;
    },
    getLocalStorageUserInfo: (): any => {
        return {};
    },
    hasLocalStorageUserInfo: (): boolean => {
        return false;
    },
});

export function UserAuthProvider(props: { children: any }) {
    const { getOrderId, linkOrderToUser, unlinkOrderFromUser, setOrder } =
        React.useContext(CartContext);
    const { site, phone, setPhone, setEmail } = React.useContext(SiteContext);
    const [recentlyViewedItems, setRecentlyViewedItems] = React.useState(
        [] as Item[],
    );
    const [user, setUserInfo] = React.useState({} as User);
    const [authenticated, setAuthenticated] = React.useState(false as boolean);
    const [jsonWebToken, setJsonWebTokenValue] = React.useState('' as string);
    const [successMessage, setSuccessMessageState] = React.useState(
        '' as string,
    );
    const [errorMessage, setErrorMessageState] = React.useState('' as string);
    const [registerErrorMessage, setRegisterErrorMessage] = React.useState(
        '' as string,
    );
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        checkForReauth();
    }, []);

    const setJsonWebToken = (token: string) => {
        if (token === '') {
            deleteCookie(COOKIE_JWT, '/', getCookieDomain());
        } else {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            setDualCookie(COOKIE_JWT, token, expirationDate, '/');
        }
        setJsonWebTokenValue(token);
    };

    const addToRecentlyViewedItem = async (item: Item) => {
        const currentCacheStr = localStorage.getItem(
            LOCAL_STORAGE_KEY_RECENTLY_VIEWED,
        );
        let cache = currentCacheStr
            ? JSON.parse(currentCacheStr)
            : { data: [], items: [] };

        // If item is already in cache, we need its prior accessTime
        const existingItemIndex = cache.items.findIndex(
            (cachedItem: { id: string | number }) => cachedItem.id === item.id,
        );
        const existingItem =
            existingItemIndex > -1 ? cache.items[existingItemIndex] : null;

        const data = cache.data.filter(
            (cachedItem: Item) => cachedItem.id !== item.id,
        );
        const items = cache.items.filter(
            (cachedItem: { id: string | number }) => cachedItem.id !== item.id,
        );

        const newItemEntry = {
            id: item.id,
            timestamp: existingItem ? existingItem.accessTime : Date.now(),
            accessTime: Date.now(),
        };

        const newData = [
            mapItemToLegacyCacheFormat(item),
            ...data.slice(0, RECENTLY_VIEWED_ITEMS_MAX - 1),
        ];
        const newItems = [
            newItemEntry,
            ...items.slice(0, RECENTLY_VIEWED_ITEMS_MAX - 1),
        ];

        cache = {
            ...cache,
            data: newData,
            items: newItems,
        };

        localStorage.setItem(
            LOCAL_STORAGE_KEY_RECENTLY_VIEWED,
            JSON.stringify(cache),
        );

        getRecentlyViewedItems();
    };

    function mapItemToLegacyCacheFormat(item: Item) {
        return {
            active: item.active ? 'true' : 'false',
            id: item.id,
            name: item.name,
            image_url: item.imageUrl,
            thumb_url: item.imageUrl,
            model_number: item.modelNumber,
            retail: item.pricing.retail,
            sale_price: item.pricing.sale.toString(),
            qty_in_stock: item.qtyAvailable,
            shipping_price: item.pricing.shipping,
            time_to_ship: item.shipsIn,
            call_for_pricing: item.isCallForPricing ? 'true' : 'false',
            slug: item.url,
            in_stock: item.inStock,
            price_formatted: item.pricingFormatted.sale,
            retail_formatted: item.pricingFormatted.retail,
            financing_price: item.pricing.financing,
            rating_count: item.userReviewsCount,
            rating_percent: (item.userReviewsRating * 100).toString(),
            promo_text: item.promotions[0]?.displayText,
            promo_text_color: item.promotions[0]?.displayTextColor,
            bullets: item.bulletPoints
                ? item.bulletPoints.filter(bp => bp !== null).map(bp => bp!)
                : [],
        };
    }

    const getRecentlyViewedItems = (): Promise<Item[]> => {
        return new Promise((resolve, reject) => {
            (async function () {
                try {
                    const itemsString = localStorage.getItem(
                        LOCAL_STORAGE_KEY_RECENTLY_VIEWED,
                    ) as string;
                    const itemData = itemsString
                        ? JSON.parse(itemsString)
                        : null;

                    if (!itemData || !Array.isArray(itemData.items)) {
                        resolve([]);
                    }

                    const itemIds = itemData.items.map((item: any) => item.id);

                    // items in cache
                    let cachedItems: Item[] = [];
                    // item ids that need lookup
                    const itemsToQuery: string[] = [];

                    itemIds.forEach((id: string) => {
                        const itm = client.readFragment({
                            id: 'Item:' + id,
                            fragment: gql`
                                ${ITEM_FIELDS}
                            `,
                            fragmentName: 'ItemFields',
                        });

                        if (itm) {
                            cachedItems.push(itm);
                        } else {
                            itemsToQuery.push(id);
                        }
                    });

                    if (itemsToQuery.length > 0) {
                        const response = await client.query({
                            query: QL_RECENTLY_VIEWED,
                            variables: {
                                itemIds: itemsToQuery.join(','),
                                pricingTierId: getPricingTierId(),
                            },
                        });

                        if (
                            !response.errors &&
                            response.data.itemsByIds?.countResults > 0
                        ) {
                            cachedItems = [
                                ...cachedItems,
                                ...response.data.itemsByIds.results,
                            ];
                        }
                    }

                    setRecentlyViewedItems(cachedItems);
                    resolve(cachedItems);
                } catch (error) {
                    reject(error);
                }
            })();
        });
    };

    const isLoggedIn = (): boolean => {
        return !isGuest();
    };
    const isGuest = (): boolean => {
        return empty(getUserId()) && empty(getJsonWebToken());
    };
    const isPro = (): boolean => {
        return user.isPro && getUserProTierId() > 0;
    };
    const getProTierId = (): number => {
        if (!isPro()) return 0;
        return getUserProTierId();
    };
    const getPricingTierId = (): number => {
        if (!isPro()) return 0;
        return getUserProTierId();
    };
    const isEmployee = (): boolean => {
        return user.isEmployee === true;
    };

    const getUserId = (): string => {
        // if(!empty(user.id)){
        //     return user.id;
        // }
        return (
            getCookie(COOKIE_USER_ID) ||
            getCookie(COOKIE_USER_ID_CART) ||
            user.id ||
            '0'
        );
    };

    const getUser = async (useCache = true): Promise<User> => {
        return new Promise((resolve, reject) => {
            (async () => {
                if (isGuest()) {
                    resolve(GUEST_INFO as User);
                } else {
                    try {
                        const response = await client.query({
                            query: QL_USER,
                            fetchPolicy: useCache ? 'no-cache' : 'cache-first',
                        });
                        if (response.errors) {
                            reject(response.errors[0]);
                        }
                        if (response.data.me) {
                            setUser(response.data.me);
                            resolve(response.data.me);
                        } else {
                            reject('User not found');
                        }
                    } catch (e) {
                        resolve(GUEST_INFO as User);
                    }
                }
            })();
        });
    };
    const setUser = (userInfo: User): void => {
        if (userInfo.proAccountRep === null) {
            userInfo.proAccountRep = GUEST_INFO.proAccountRep;
        } else {
            if (userInfo.proAccountRep?.phone) {
                setPhone(userInfo.proAccountRep?.phone);
            }
            if (userInfo.proAccountRep?.email) {
                setEmail(userInfo.proAccountRep?.email);
            }
        }
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        if (!empty(userInfo.id)) {
            setDualCookie(COOKIE_USER_ID, userInfo.id, expirationDate, '/');
        } else {
            deleteCookie(COOKIE_USER_ID, '/', getCookieDomain());
            deleteCookie(COOKIE_USER_ID_CART, '/', getCookieDomain());
            localStorage.removeItem(LOCAL_STORAGE_KEY_USERINFO);
        }
        if (!empty(userInfo.pricingTierId)) {
            localStorage.setItem(LOCAL_STORAGE_KEY_PRO, 'true');
            localStorage.setItem(
                LOCAL_STORAGE_KEY_PRO_TIER_ID,
                `${userInfo.pricingTierId}`,
            );
            setDualCookie(
                COOKIE_PRO_TIER_ID,
                `${userInfo.pricingTierId}`,
                expirationDate,
                '/',
            );
        }
        if (!empty(userInfo.id)) {
            localStorage.setItem(
                LOCAL_STORAGE_KEY_USERINFO,
                JSON.stringify(userInfo),
            );
        }
        setUserInfo(userInfo);
    };

    const hasLocalStorageUserInfo = () => {
        const userInfoString: string | null = localStorage.getItem(
            LOCAL_STORAGE_KEY_USERINFO,
        );
        return !!userInfoString;
    };

    const getLocalStorageUserInfo = () => {
        const userInfoString: string | null = localStorage.getItem(
            LOCAL_STORAGE_KEY_USERINFO,
        );
        if (!userInfoString) {
            return {};
        }
        try {
            return JSON.parse(userInfoString);
        } catch (e) {
            return {};
        }
    };

    const getUserAddresses = async (): Promise<UserAddressesResponse> => {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const response = await client.query({
                        query: QL_USER_ADDRESSES,
                    });
                    if (response.errors) {
                        reject(response.errors[0]);
                    }
                    if (response.data.userAddresses) {
                        resolve(response.data.userAddresses);
                    } else {
                        reject('User addresses not found');
                    }
                } catch (e) {
                    resolve({} as UserAddressesResponse);
                }
            })();
        });
    };

    const logout = (): Promise<LogoutResponse> => {
        const oId = getOrderId();
        const jwt = getJsonWebToken();
        return new Promise((resolve, reject) => {
            logoutClientSide().then(async (resp: LogoutResponse) => {
                if (jwt && oId) {
                    const response = await client.mutate({
                        mutation: QL_USER_LOGOUT,
                        variables: {
                            jwt: jwt || '',
                            orderId: oId,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        throw response.errors[0];
                    }
                }
                const requestOptions: any = {
                    method: 'post',
                    redirect: 'follow',
                    credentials: 'include',
                    body: JSON.stringify({ orderID: oId }),
                    mode: 'no-cors',
                };
                // used for maintaining login while on old site (can remove when old site is no longer used)
                fetch(getOldSiteDomain() + '/user-logout-ajax', requestOptions)
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        resolve(resp); // keep this resolve when removing old login support
                    });
            });
        });
    };
    const logoutClientSide = (): Promise<LogoutResponse> => {
        return new Promise((resolve, reject) => {
            let unLinkedOrder: Maybe<Order> | undefined;
            unlinkOrderFromUser(user)
                .then(o => {
                    unLinkedOrder = o;
                    return true;
                })
                .catch(() => {
                    return true;
                })
                .finally(() => {
                    setLoginRedirectUrl();
                    clearUserFromStorage();
                    setAuthenticated(false);
                    setErrorMessage('');
                    setSuccessMessage('');
                    setJsonWebToken('');
                    setTimeout(() => {
                        setUser(GUEST_INFO as User);
                        setOrder(unLinkedOrder as Order);
                        resolve({ order: unLinkedOrder as Order });
                    }, 50);
                });
        });
    };
    const validateAndSetJsonWebToken = (token: string) => {
        const data = parseJwt(token);
        if (
            data.iss === site.domain &&
            data.exp * 1000 > new Date().getTime()
        ) {
            setJsonWebToken(token);
            return true;
        } else {
            logoutClientSide();
        }
        return false;
    };
    const checkForReauth = () => {
        const jwt = getJsonWebToken();
        if (jwt) {
            const tokenData = parseJwt(jwt);
            const expDate = new Date(tokenData.exp * 1000);
            const now = new Date().getTime();
            const expired = expDate.getTime() < now;
            // if our auth has expired, logout.
            if (expired) {
                logoutClientSide();
            } else {
                // if we are within X minutes of our auth expiring, we can reauth and keep the session open
                expDate.setMinutes(expDate.getMinutes() - 5);
                const aboutToExpire = expDate.getTime() < now;
                if (aboutToExpire) {
                    reauthorize();
                }
            }
        } else if (!empty(getCookie('jwt'))) {
            reauthorize();
        } else {
            logoutClientSide();
        }
    };
    const reauthorize = async (): Promise<string | unknown> => {
        return new Promise((resolve, reject) => {
            (async function () {
                setErrorMessage('');
                setSuccessMessage('');
                reauthenticateUser(parseInt(site.id))
                    .then(data => {
                        if (data && data.user && data.token) {
                            const user = data.user as User;
                            if (validateAndSetJsonWebToken(data.token)) {
                                if (user.isPro) {
                                    localStorage.setItem(
                                        LOCAL_STORAGE_KEY_PRO,
                                        'true',
                                    );
                                    localStorage.setItem(
                                        LOCAL_STORAGE_KEY_PRO_TIER_ID,
                                        (user.pricingTierId + '') as string,
                                    );
                                }
                                setUser(user);
                                setAuthenticated(true);
                                resolve(data.token);
                            } else {
                                throw new Error('Invalid token');
                            }
                        } else {
                            throw new Error(
                                data.errorMessage ?? 'Invalid token',
                            );
                        }
                    })
                    .catch(errMessage => {
                        logoutClientSide();
                        setErrorMessage(errMessage);
                        reject(errMessage);
                    });
            })();
        }).catch(e => {
            console.log(e);
            setAuthenticated(false);
            setSuccessMessage('');
            setErrorMessage('Your session has expired');
            navigate('/login');
        });
    };
    const login = async (
        email: string,
        password: string,
    ): Promise<LoginResponse> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const response = await client.mutate({
                    mutation: QL_USER_LOGIN,
                    variables: {
                        email: email,
                        password: password,
                        site: site.id,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    throw response.errors[0];
                }
                if (response.data.login.authenticated) {
                    const user = response.data.login.user as User;
                    client.cache.reset();
                    client.cache.gc();
                    setAuthenticated(true);
                    if (validateAndSetJsonWebToken(response.data.login.token)) {
                        let linkedOrder: Maybe<Order> | undefined;
                        setUser(user);
                        linkOrderToUser(user)
                            .then(o => {
                                linkedOrder = o;
                                return;
                            })
                            .catch(() => {
                                return;
                            })
                            .finally(() => {
                                // used for maintaining login while on old site (can remove when old site is no longer used)
                                const formData = new FormData();
                                formData.append('email', email);
                                formData.append('password', password);
                                formData.append('inCart', 'yes');
                                const oId = getOrderId();
                                if (oId) {
                                    formData.append('order_id', oId);
                                }

                                const requestOptions: any = {
                                    method: 'post',
                                    body: formData,
                                    credentials: 'include',
                                    mode: 'no-cors',
                                };

                                fetch(
                                    getOldSiteDomain() + '/user-login-ajax',
                                    requestOptions,
                                ).catch(err => {
                                    console.log(err);
                                });
                                resolve({
                                    order: linkedOrder as Order,
                                    token: response.data.login.token,
                                });
                            });
                    } else {
                        reject('You session has expired. Please login again.');
                    }
                } else {
                    reject(response.data.login.errorMessage);
                }
            })();
        });
    };
    const register = (email: string, password: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            (async function () {
                setRegisterErrorMessage('');
                const response = await client.mutate({
                    mutation: QL_USER_REGISTER,
                    variables: {
                        email: email,
                        password: password,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    throw response.errors[0];
                }
                if (response.data.register.authenticated) {
                    const user = response.data.register.user as User;
                    setAuthenticated(true);
                    setJsonWebToken(response.data.register.token);
                    if (user.isPro) {
                        localStorage.setItem(LOCAL_STORAGE_KEY_PRO, 'true');
                        localStorage.setItem(
                            LOCAL_STORAGE_KEY_PRO_TIER_ID,
                            (user.pricingTierId + '') as string,
                        );
                        setDualCookie(
                            COOKIE_PRO_TIER_ID,
                            `${user.pricingTierId}`,
                        );
                    }
                    setUser(user);
                    resolve(response.data.register.token);
                } else {
                    setRegisterErrorMessage(
                        response.data.register.errorMessage,
                    );
                    reject(response.data.register.errorMessage);
                }
            })();
        });
    };
    const passwordReset = (email: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            (async function () {
                setErrorMessage('');
                const response = await client.mutate({
                    mutation: QL_USER_PASSWORD_RESET,
                    variables: {
                        email: email,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    throw response.errors[0];
                }
                if (response.data.resetPassword.success) {
                    setSuccessMessage('Password reset');
                    resolve(response.data.resetPassword.expires);
                } else {
                    setErrorMessage(response.data.resetPassword.errorMessage);
                    reject(response.data.resetPassword.errorMessage);
                }
            })();
        });
    };
    const updatePassword = (
        email: string,
        token: string,
        newPassword: string,
    ): Promise<string> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const response = await client.mutate({
                    mutation: QL_USER_PASSWORD_UPDATE,
                    variables: {
                        email: email,
                        token: token,
                        newPassword: newPassword,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    throw response.errors[0];
                }
                if (response.data.updatePassword.success) {
                    resolve(response.data.updatePassword.expires);
                } else {
                    reject(response.data.updatePassword.errorMessage);
                }
            })();
        });
    };

    const setRegistrationErrorMessage = (message: string) => {
        setRegisterErrorMessage(message);
    };

    const setErrorMessage = (message: string) => {
        setErrorMessageState(message);
    };
    const setSuccessMessage = (message: string) => {
        setSuccessMessageState(message);
    };

    const accountPath = '/account';

    const setLoginRedirectUrl = () => {
        const pathName = location.pathname;

        const loginPath = '/login';

        if (pathName == loginPath || pathName == accountPath) {
            // Do not delete or overwrite path
            return;
        }

        const doNotSetUrlPaths = [
            // Forgot/reset password pages
            '/forgot',
            '/sent-forgot',
            '/sentforgot',
            '/do-forget',
            '/update-password',

            // No access to account pages
            '/account',
            '/account/change-address',
            '/account/change-password',
            '/account/do-login',
            '/account/gift-card-balance',
            '/account/manage-my-lists',
            '/account/my-account',
            '/account/payments',
            '/account/payments/gift-card-balance',
            '/account/payments/points',
            '/account/payments/gift-card-balance',
            '/account/reward-points/history',
            '/account/refer-users',

            // No access to cart complete page on second load
            '/cart/complete',
        ];

        let locationUrl = location.pathname + location.search;

        if (doNotSetUrlPaths.indexOf(pathName) != -1) {
            locationUrl = accountPath;
        }

        // This is a path where the user is redirected to the account
        localStorage.setItem(LOCAL_STORAGE_KEY_LOGIN_REDIRECT_URL, locationUrl);
    };
    const getLoginRedirectUrl = () => {
        let redirectUrl = localStorage.getItem(
            LOCAL_STORAGE_KEY_LOGIN_REDIRECT_URL,
        );
        if (!redirectUrl) {
            redirectUrl = accountPath;
        }

        return redirectUrl;
    };

    const getProAccountRepPhoto = () => {
        return (
            user.proAccountRep?.photo ??
            'https://cdn.shocho.co/sc-site/redesign/people/ask-the-experts--delva.png?i10c=img.resize(width:250,height:250)'
        );
    };

    const getProAccountRepPhoneNumber = () => {
        return user.isPro ? user.proAccountRep?.phone ?? phone : phone;
    };

    return (
        <UserAuth.Provider
            value={{
                user,
                getUserId,
                jsonWebToken,
                authenticated,
                errorMessage,
                successMessage,
                registerErrorMessage,
                recentlyViewedItems,
                getProAccountRepPhoto,
                isLoggedIn,
                isPro,
                getProTierId,
                getPricingTierId,
                isGuest,
                isEmployee,
                getUser,
                setUser,
                getUserAddresses,
                register,
                login,
                logout,
                reauthorize,
                checkForReauth,
                passwordReset,
                updatePassword,
                addToRecentlyViewedItem,
                getRecentlyViewedItems,
                setRegistrationErrorMessage,
                setErrorMessage,
                setSuccessMessage,
                setLoginRedirectUrl,
                getLoginRedirectUrl,
                getProAccountRepPhoneNumber,
                getLocalStorageUserInfo,
                hasLocalStorageUserInfo,
            }}
        >
            {props.children}
        </UserAuth.Provider>
    );
}
